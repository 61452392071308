<script>

// import { library } from '@fortawesome/fontawesome-svg-core';
//import { faArrowAltToRight } from '@fortawesome/free-solid-svg-icons';
//import { faLinkedinIn, faInstagram, faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons'
// library.add(faArrowAltToRight)
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import $ from 'jquery'; 


//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '../mixins/GeneralFunctions';

import axios from 'axios';

export default {
	name: 'Program',
	mixins: [ MixinGeneralFunct ],
	components: {
		"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: ["myUserReg","myEventReg","myEventCounts"],
	data() {
		return {
			programData: []
			,obsData: {
				'popup0':''
				,'fontsize0':'1em'
				,'popup1':''
				,'fontsize1':'1em'
				,'meno':''
				,'funkcia':''
			}
			,selectedResult:{
				'speakers':[]
			}
			,progIndex:''
			,itemIndex:''
			,showPanel:true
			,menovkaTiming:true
			,menovkaTimingSet:false
		}
	},
	
	mounted:function(){
		
		this.loadDefaultValues();
		this.bcpopup = new BroadcastChannel('obs-popup-channel');
		this.bcmenovka = new BroadcastChannel('obs-menovka-channel');
		this.bcmenovkaMala = new BroadcastChannel('obs-menovkaMala-channel');

		this.checkTime();
		setInterval(this.checkTime, 5000);
	},
	
	methods: {
		
		//obs-popup-channel
		obsPopupChannel:function(){
			this.bcpopup.postMessage(this.obsData.popup0 + '|' + this.obsData.popup1 + '|' + this.obsData.fontsize0 + '|' + this.obsData.fontsize1 + '|' + 'animateIn');

			//reset broadcasted
			for(let i=0;i<this.programData.arrProgram.length;i++){
				for(let j=0;j<this.programData.arrProgram[i].items.length;j++){
					this.programData.arrProgram[i].items[j].broadcasted=false;
				}
			}

			//set broadcasted
			if(this.itemIndex){
				this.programData.arrProgram[this.progIndex].items[this.itemIndex].broadcasted=true;
			}

		},
		obsPopupChannelHide:function(){
			this.bcpopup.postMessage('||||animateOut');

			//reset broadcasted
			for(let i=0;i<this.programData.arrProgram.length;i++){
				for(let j=0;j<this.programData.arrProgram[i].items.length;j++){
					this.programData.arrProgram[i].items[j].broadcasted=false;
				}
			}
		},
		
		//obs-menovka-channel
		obsMenovkaChannel:function(){
			this.bcmenovka.postMessage(this.obsData.meno + '|' + this.obsData.funkcia + '|' + 'animateIn');
			this.bcmenovkaMala.postMessage(this.obsData.meno);

			//reset broadcastedName
			for(let i=0;i<this.programData.arrProgram.length;i++){
				for(let j=0;j<this.programData.arrProgram[i].items.length;j++){
					this.programData.arrProgram[i].items[j].broadcastedName=false;
				}
			}

			//set broadcastedName
			this.programData.arrProgram[this.progIndex].items[this.itemIndex].broadcastedName=true;

			//set auto hiding
			if(this.menovkaTiming){
				this.menovkaTimingSet = setTimeout(this.obsMenovkaChannelHide, 10000);
			}
		},

		obsMenovkaChannelHide:function(){
			this.bcmenovka.postMessage('||animateOut');
			this.bcmenovkaMala.postMessage('');

			//reset broadcastedName
			for(let i=0;i<this.programData.arrProgram.length;i++){
				for(let j=0;j<this.programData.arrProgram[i].items.length;j++){
					this.programData.arrProgram[i].items[j].broadcastedName=false;
				}
			}

			clearTimeout(this.menovkaCasovanieTime);
		},


		



		

		//default data
		loadDefaultValues:function(){
			var self = this;
			axios.get('/app/includeData/Obs/getObsData.php')
			.then(function (response) {
				console.log(response.data);
				
				//hide loader
				$('#preloader').fadeOut(200);
				
				self.programData = response.data.programData;
				self.checkTime();
			})
				.catch(function (error) {
				console.log(error);
			});
		},
		
		//kontrola casu
		checkTime:function(){
			if(this.programData.arrProgram){
				for(let i=0;i<this.programData.arrProgram.length;i++){
					for(let j=0;j<this.programData.arrProgram[i].items.length;j++){
						if(this.programData.arrProgram[i].items[j].begin_time && this.programData.arrProgram[i].items[j].end_time){
							const currTime = new Date();
							const beginTime = new Date(this.programData.arrProgram[i].items[j].begin_time);
							const endTime = new Date(this.programData.arrProgram[i].items[j].end_time);
							
							if(currTime>=beginTime && currTime<endTime){
								this.programData.arrProgram[i].items[j].playedNow=true;
								this.programData.arrProgram[i].items[j].playedOut=false;
								this.programData.arrProgram[i].items[j].playedNext=false;
							}else if (currTime>=endTime){
								this.programData.arrProgram[i].items[j].playedNow=false;
								this.programData.arrProgram[i].items[j].playedOut=true;
								this.programData.arrProgram[i].items[j].playedNext=false;
							}else{
								this.programData.arrProgram[i].items[j].playedNow=false;
								this.programData.arrProgram[i].items[j].playedOut=false;
								this.programData.arrProgram[i].items[j].playedNext=true;
							}

							
						}

					}
				}
			}
		},
		
		handleSelected:function(itemIndex,progIndex,itemId){
			//reset selected
			for(let i=0;i<this.programData.arrProgram.length;i++){
				for(let j=0;j<this.programData.arrProgram[i].items.length;j++){
					this.programData.arrProgram[i].items[j].selected=false;
				}
			}
			
			//set selected
			this.programData.arrProgram[progIndex].items[itemIndex].selected=true;

			//ucel:vyznacenie broadcasted
			this.progIndex=progIndex;
			this.itemIndex=itemIndex;

			this.selectedResult = this.programData.allItems.find(item => item.id===itemId);

			this.obsData.popup0 = this.selectedResult.title_lecture || this.selectedResult.title_non_lecture;
			this.obsData.popup1 = this.selectedResult.description;

			if(this.selectedResult.speakers.length>0){
				if(this.selectedResult.speakers.length>1){
					this.obsData.meno = `${this.selectedResult.speakers[0].firstname} ${this.selectedResult.speakers[0].surname}`;
					for(let i=1;i<this.selectedResult.speakers.length;i++){
						this.obsData.meno += `, ${this.selectedResult.speakers[i].firstname} ${this.selectedResult.speakers[i].surname}`;
					}
				}else{
					this.obsData.meno = `${this.selectedResult.speakers[0].firstname} ${this.selectedResult.speakers[0].surname}`;
				}
			}else{
				this.obsData.meno = '';
			}

			if(this.selectedResult.speakers.length>0){
				if(this.selectedResult.speakers.length>1){
					this.obsData.funkcia = '';
				}else{
					this.obsData.funkcia = `${this.selectedResult.speakers[0].position}`;
					if(this.selectedResult.speakers[0].company){
						this.obsData.funkcia = `${this.selectedResult.speakers[0].position}, ${this.selectedResult.speakers[0].company}`;
					}
				}
			}else{
				this.obsData.funkcia = '';
			}
	
		},

		setName:function(name,surname,company,position){
			
			this.obsData.meno = `${name} ${surname}`;
			
			this.obsData.funkcia = `${position}`;
			if(company){
				this.obsData.funkcia = `${position}, ${company}`;
			}
	
		},

		setAllNames:function(){
			
			if(this.selectedResult.speakers.length>1){
				this.obsData.meno = `${this.selectedResult.speakers[0].firstname} ${this.selectedResult.speakers[0].surname}`;
				for(let i=1;i<this.selectedResult.speakers.length;i++){
					this.obsData.meno += `, ${this.selectedResult.speakers[i].firstname} ${this.selectedResult.speakers[i].surname}`;
				}
			}else{
				this.obsData.meno = `${this.selectedResult.speakers[0].firstname} ${this.selectedResult.speakers[0].surname}`;
			}

			this.obsData.funkcia = '';
	
		},

		// daj button
		showDiscuss:function(){
			
			this.obsData.popup0 = `Otázky môžete položiť cez diskusia.pp.sk KÓD: ${this.programData.event.access_code}`;
			this.obsData.meno = '';
			this.progIndex = ''
			this.itemIndex = ''
		}
	},	
	
			
	watch: {
		myEventReg() {
			this.loadDefaultValues();
		}
		
	},

	computed:{
		
	},
	
}

</script>

<template>
	
	<div>
		<div id="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
		

		<section v-if="(programData.resCode==1 && showPanel)" id="obs-panel" class="">
			<div class="container">

				<div class="row">
					
					<div class="col-2">

						<div class="row">
							<div class="col-12 text-center pb-1">
								<button class="btn btn-sm btn-success" v-on:click="obsPopupChannel()">Popup zobraz</button>
							</div>
							<div class="col-12 text-center pb-1">
								<button class="btn btn-sm btn-danger" v-on:click="obsPopupChannelHide()">Popup skry</button>
							</div>
							<div class="col-12 text-center pb-1">
								<button class="btn btn-sm btn-success" v-on:click="obsMenovkaChannel()">Meno zobraz</button>
							</div>
							<div class="col-12 text-center pb-1">
								<button class="btn btn-sm btn-danger" v-on:click="obsMenovkaChannelHide()">Meno skry</button>
							</div>
						</div>

					</div>

					<div class="col-8">

						<div class="row">
							<div class="col-8">
								<input class="form-control form-control-sm" type="text" v-model="obsData.popup0">
							</div>

							<div class="col-4">
								<input class="form-control form-control-sm" type="text" v-model="obsData.fontsize0">
							</div>
						</div>
						<div class="row">
							<div class="col-8">
								<input class="form-control form-control-sm" type="text" v-model="obsData.popup1">
							</div>

							<div class="col-4">
								<input class="form-control form-control-sm" type="text" v-model="obsData.fontsize1">
							</div>
						</div>

						<div class="row">
							<div class="col-12">
								<input class="form-control form-control-sm" type="text" v-model="obsData.meno">
							</div>
						</div>

						<div class="row">
							<div class="col-12">
								<input class="form-control form-control-sm" type="text" v-model="obsData.funkcia">
							</div>
						</div>

						<div v-if="(selectedResult.speakers.length>1)" class="row pb-2 pt-2">
							<div class="col-12">
								<button v-for="item in selectedResult.speakers" :key="item.id" v-on:click="setName(item.firstname,item.surname,item.company,item.position)" class="btn btn-sm btn-primary mx-1" >{{item.firstname}} {{item.surname}}</button>
								<button class="btn btn-sm btn-primary mx-1" v-on:click="setAllNames()" >Všetci</button>
							</div>
						</div>

					</div>

					<div class="col-2">

						<div class="row">
							<div class="col-12 text-center pb-1">
								<button class="btn btn-sm btn-primary" v-on:click="showDiscuss()">Disk. {{this.programData.event.access_code}}</button>
							</div>
						</div>

					</div>

				</div>
			</div>
		</section>

		<section v-if="programData.resCode==1" id="obs" class="">
			
			<div class="container">

				<div class="row pt-5">
					<div class="col-12">
						
						<ul class="nav nav-tabs" role="tablist">
							
							<li  v-for="(prog,index) in programData.arrProgram" v-bind:key="prog.id" class="nav-item">
								<a class="nav-link" :class="{'active':index==0}" :id="'menu' + index + '-tab'" data-toggle="tab" :href="'#menu'+index" role="tab" :aria-controls="'menu'+index" aria-selected="true">{{prog.name}}</a>
							</li>

						</ul>
					
					</div>
					
					<div class="tab-content" id="myTabContent">
						<div v-for="(prog,index) in programData.arrProgram" v-bind:key="prog.id" :id="'menu'+index" class="tab-pane fade" :class="{'active':index==0,'show':index==0}" role="tabpanel">
						
							<div class="row block">
								<div class="col-12">
								
									<div class="people">
																			
										<table v-if="prog.items.length > 0" class="table">
											
												<tr v-for="(item,itemIndex) in prog.items" :key="item.id" class="obs-row" :class="{'obs-row-selected':item.selected}" v-on:click="handleSelected(itemIndex,index,item.id)" >
													
													
													<td class="text-center">
														<span v-if="item.playedOut" class="faIcon text-success"><font-awesome-icon :icon="['fas', 'check']" /></span>
														<span v-if="item.playedNow" class="faIcon text-success"><font-awesome-icon :icon="['fas', 'arrow-alt-circle-right']" /></span>
														<span v-if="item.playedNext" class="faIcon text-orange"><font-awesome-icon :icon="['fas', 'arrow-alt-circle-right']" /></span>
													</td>

													<td v-if="item.begin_timeView" :class="{'playedOut':item.playedOut, 'playedNow':item.playedNow}">{{item.begin_timeView}}&nbsp;-&nbsp;{{item.end_timeView}}
														<br>
														<span v-if="item.timeView" class="time">{{item.timeView}} min</span>
													</td>
													<td v-else></td>
													
													<td>
														<span v-if="item.title_block" :class="{'titleBlock':item.title_block}">{{item.title_block}}</span>
														<span v-if="item.subtitle_block" :class="{'subtitleBlock':item.subtitle_block}"><br>{{item.subtitle_block}}</span>
														<span v-if="(item.title_block || item.subtitle_block) && (item.title_lecture || item.title_non_lecture)"><br></span>
														
														<span v-if="item.title_lecture" :class="{'titleLecture':item.title_lecture,'obs-row-broadcasted':item.broadcasted}">{{item.title_lecture}}</span>
														<span v-else-if="item.title_non_lecture" :class="{'titleNonLecture':item.title_non_lecture,'obs-row-broadcasted':item.broadcasted}">{{item.title_non_lecture}}</span>
														
														
														<br v-if="item.description">
														<span :class="{'description':item.description}" v-html="item.description"></span>
														
														<span v-if="item.speakers.length>0">
														
															<span v-for="spk in item.speakers" v-bind:key="spk.speakerId" class="speaker" :class="{'obs-row-broadcastedName':item.broadcastedName}">
																
																
																<br>
																<span class="name">{{spk.firstname}} {{spk.surname}}</span> | {{spk.position}} | {{spk.company}}
																
															</span>
														</span>
													</td>
													<td class="speakers-photo">
														<span v-if="item.speakers.length>0">
															
															
																<img v-for="spk in item.speakers" v-bind:key="spk.speakerId" :src="spk.photoDataOrigo" :alt="spk.firstname+' '+spk.surname" class="img-fluid img-speaker" />
															
														</span>

													</td>
												</tr>
											
										</table>
										
									
									</div>
								</div>		
							</div>
						
						</div>
					</div>
					
				</div>
			</div>
		</section>
		
		<section v-else-if="programData.resCode==2">
			<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Pre zobrazenie tejto sekcie musíte byť prihlásený</h5>
				</div>
			</div>
			</div>
		</section>
			
		<section v-else-if="programData.resCode==3">
			<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Chýbajú vstupné údaje</h5>
				</div>
			</div>
			</div>
		</section>
				
		<section v-else-if="programData.resCode==0">
			<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Program je prázdny</h5>
				</div>
			</div>
			</div>
		</section>

		<section v-if="programData.resCode==1" id="obs-control" class="">
			<div class="container">

				<div class="row pt-3">
					<div class="col-12 text-center">
						<span class="togglebutton">
								<label>
									<input v-model='showPanel' type="checkbox" >
									<span class="toggle"></span>
									<br><span class="button-panel">Panel</span>
								</label>
							</span>
					</div>
					
				</div>

				<div class="row pt-3 pb-2">
					<div class="col-12 text-center">
						<span class="togglebutton">
								<label>
									<input v-model='menovkaTiming' type="checkbox" >
									<span class="toggle"></span>
									<br><span class="button-panel">Čas.men.</span>
								</label>
							</span>
					</div>
					
				</div>

			</div>
		</section>

	</div>
	
</template>


