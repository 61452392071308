<script>

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
//, reactive, toRefs
import { defineComponent } from 'vue';
import { QrStream } from 'vue3-qr-reader' //hl29
//import { QrcodeCapture } from 'vue3-qrcode-reader' //novy
// QrcodeStream
//import { QrCapture, QrStream } from 'vue3-qr-reader';
import $ from 'jquery'; 
//import from 'print-this'; 


//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '../mixins/GeneralFunctions';

import axios from 'axios';

export default defineComponent ({
	name: 'QRreader',
	mixins: [ MixinGeneralFunct ],
	components: {
		"font-awesome-icon":FontAwesomeIcon,
		//,"video-player":VideoPlayer
		QrStream,
		//QrCapture,
		
		//QrcodeStream, //testuj
		//QrcodeCapture //testuj
		//QrDropzone
	},
	
	props: ["myUserReg","myEventReg","myEventCounts","event"],
	
	/*setup(props) {
		console.log(props);
		const state = reactive({
			qrCode: null
		})
		function onDecode(data) {
			state.qrCode = data
			console.log(data);
		}
		return {
			...toRefs(state),
			onDecode,
		}
	},*/
	
	data() {
		return {
			prefix:'',
						
			accData: [],
			secData: [],
			auth: [],
			menuItems: [],
			cookiesTime: 259200,
			
			eventIsRegistered:false,
			eventRegCode:'',
			selectedAccessCode:'',
			
			loadInterval:'',
			
			isLogged:true,
			
			alert:[],
			alertEdit:[],
			
			myEvent:[],
			
			
			optionsTypes:[],
			
			readerData: [],
			
			helpboxData: [],
			
			//alertsCount:0,
			ticket:[],
			ticketPrint:[],
			resCode:'',
			labelFontSize:26,
			labelFontSizeCompany:16,
			
			timeToRefresh:10000,
			
			firstLoading:true,
			tmpQuestions:[],
	
			countPages:0,
			selectedPage:null,
			selectedRecords:null,
			limit_from:0,
			limit_to:0,
			
			isSelectedQuestion:false,
			curSelectedQuestionID:'',
			
			selectedCol:'created',
			selectedSort:'desc',
		
			filterExtended:true,
			showFilter:false,
			
			myCode:'',
			decodedString:'',
			
			decodedString1:'',
			stateReader:true,
			
			
			options: {
				type: Object,
				default() {
					return {};
				}
			}
			
		}
	},
	
	
	
	mounted:function(){
		
			this.loadDefaultData();
		
		
	},
	
	methods: {
				
		//zoznam otazok
		loadDefaultData:function(){
			
			var self = this;
			axios.get('/app/includeData/QRreader/getReaderData.php',{
				params:{
					//code:c,
					//selectedIds: this.selectedIds
				}
			})
			.then(function (response) {
				//console.log("loadDefaultData");
				console.log(response.data);
				
				//hide loader
				$('#preloader').fadeOut(200);
				
				//self.alertsCount = response.data.basicDataCount;
				//self.alerts = response.data.basicData;
				self.readerData = response.data.readerData;
				
				//self.myUser = response.data.myUser;
			
			})
				.catch(function (error) {
				console.log(error);
			});
		},
		
		//tu dopln type
		checkCode:function(){
			console.log(this.qrCode)
		},
		
		
		
		onDecode:function(code) {
			if(code){
				console.log(code)
				this.decodedString=code;
				this.stopReader();
				
				var bodyFormData = new FormData();
				bodyFormData.set('code',code);
				
				var self = this;
				axios({
					method: 'post',
					url: '/app/includeData/QRreader/getTicketData.php',
					data: bodyFormData
				})
				.then(response => {
					if(response.data.error){
							console.log('error', response.data.error)
					}else{
						console.log(response.data);
						self.ticket=response.data.ticket;
						self.resCode=response.data.resCode;
												
						$('#myModalTicket').addClass('modal');
						$('#myModalTicket').modal({backdrop: "static"});
										
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
				
				
				
			}
			
		},
		
		//messagebox v1.1
		createMessageBoxNoTrans: function(kind,object,message) {
			var content = "";
			content += "<div class='alert alert-"+kind+"' role='alert'>";
			content += message;
			content += "</div>";
			
			var rand = this.getRandomInt(600000);
			$('#msg').append("<div class='msg"+rand+"'></div>");
			$('.msg'+rand).html("").append(content).fadeIn(100).delay(6000).fadeOut(300);
		},
		
		//messagebox v1.1
		createMessageBoxTrans: function(kind,object,message) {
			var content = "";
			content += "<div class='alert alert-"+kind+"' role='alert'>";
			content += this.$t('message.'+message);
			content += "</div>";
			
			var rand = this.getRandomInt(600000);
			$('#msg').append("<div class='msg"+rand+"'></div>");
			$('.msg'+rand).html("").append(content).fadeIn(100).delay(6000).fadeOut(300);
		},
		
		// zatvaranie modalbox
		closeModalBoxSecure:function(myform){
			$("#"+myform).modal("hide");
			$("#"+myform).removeClass("modal");
			this.startReader();
			
		},
		
		stopReader:function() {
			this.stateReader=false;
		},
		
		startReader:function() {
			this.stateReader=true;
			this.ticket=[];
		},
		
		setFontSizeLabel:function(){
			const textLength = this.ticketPrint.firstname.length+this.ticketPrint.surname.length;
			if(textLength > 22){
				this.labelFontSize=18;
			}else if(textLength > 16){
				this.labelFontSize=22;
			}else if(textLength > 12){
				this.labelFontSize=26;
			}
		},
			
		//schvalenie listka
		approveTicket:function(){
			if(this.ticket.id){
				console.log(this.ticket.id);
				var bodyFormData = new FormData();
				bodyFormData.set('custAccessId',this.ticket.id);
				
				var self = this;
				axios({
					method: 'post',
					url: '/app/includeData/QRreader/controlApprove.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						console.log('approveTicket', response.data);
						if(response.data.resCode==1){
							self.createMessageBoxNoTrans('success','Alert','OK');
							self.closeModalBoxSecure('myModalTicket')
							self.startReader();
						}else if(response.data.resCode==0){
							self.createMessageBoxTrans('warning','Alert','dataCantBeSaved');
						}else if(response.data.resCode==2){
							self.createMessageBoxTrans('danger','Alert','dataWasNotSaved');
						}else if(response.data.resCode==3){
							self.createMessageBoxTrans('danger','Alert','accessDenied');
						}
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
			
			}
	
		},
		
		//tlac listka
		printLabel:function(){
			if(this.ticket.id){
				this.ticketPrint=this.ticket;
				
				//kontrola dlzky text a set velkosti
				this.setFontSizeLabel();
				
				var bodyFormData = new FormData();
				bodyFormData.set('custAccessId',this.ticket.id);
				
				var self = this;
				axios({
					method: 'post',
					url: '/app/includeData/QRreader/controlApprove.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						console.log('approveTicket', response.data);
						if(response.data.resCode==1){
							self.createMessageBoxNoTrans('success','Alert','OK');
							$('#printArea2').printThis({
								importStyle: true
							});	
							
							$("#myModalTicket").modal("hide");
							$("#myModalTicket").removeClass("modal");
							self.startReader();
						}else if(response.data.resCode==0){
							self.createMessageBoxTrans('warning','Alert','dataCantBeSaved');
						}else if(response.data.resCode==2){
							self.createMessageBoxTrans('danger','Alert','dataWasNotSaved');
						}else if(response.data.resCode==3){
							self.createMessageBoxTrans('danger','Alert','accessDenied');
						}
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
			
			}
	
		},

	},	
	
			
	watch: {
		/*myUserReg() {
			this.loadDefaultData();
		},
		myEventReg() {
			this.loadDefaultData();
		},*/
		qrCode() {
			this.checkCode();
			console.log(this.qrCode);
		}
	},

	computed:{
		
	},
	
});






</script>

<template>
	
	<div id="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
	
	<section v-if="readerData.resCode==1" class="">
	
		<!--<div v-on:click="loadModalNewAlert()" :title="$t('message.AddAlert')" class="addAlertBtn box-flex"><font-awesome-icon :icon="['fas', 'pen']" /></div>-->
		
						
		<section id="obsah">
			<div class="container">
				
				<div class="row">
				
					<div class="col-12 col-md-6 offset-md-3">
							
						
						
						<div v-if="stateReader">
							<qr-stream @decode="onDecode" class="mb"></qr-stream>
						</div>		
						
											
						
					</div>
					
					<!--<div class="col-12 col-md-10 offset-md-1">
							
						<qrcode-stream @decode="onDecodeX"></qrcode-stream>

						xx: {{ decodedString }}
						<qrcode-capture @decode="onDecodeX" />
						
						
					</div>-->
				
				</div>
				
			</div>
		</section>
		
	</section>
	
	<section v-else-if="readerData.resCode==2">
		<div class="container">
		<div class="row">
			<div class="col-12 text-center">
				<h5 style="padding: 50px 0;">Pre zobrazenie tejto sekcie musíte byť prihlásený ako správca a mať pridelené príslušné oprávnenia</h5>
			</div>
		</div>
		</div>
	</section>
		
	<section v-else-if="readerData.resCode==3">
		<div class="container">
		<div class="row">
			<div class="col-12 text-center">
				<h5 style="padding: 50px 0;">Chýbajú vstupné údaje</h5>
			</div>
		</div>
		</div>
	</section>
			
	<section v-else-if="readerData.resCode==0">
		<div class="container">
		<div class="row">
			<div class="col-12 text-center">
				<h5 style="padding: 50px 0;">K záznamu nemáte prístup</h5>
			</div>
		</div>
		</div>
	</section>
	
	
	<div id="myModalTicket" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog" style="">
			<div class="modal-content">
				
				<div class="modal-header">
					<h6 class="modal-title"><span>{{ $t('message.CheckTicket') }}</span></h6>
					<div class="modal-button-close" v-on:click="closeModalBoxSecure('myModalTicket')" ><font-awesome-icon :icon="['fas', 'times']" /></div>
					
				</div>
		
				<div class="modal-body">
					<div v-if="resCode==1" class="row">
						<div class="col-12">
							<div class="alert alert-success" role="alert">
								Lístok je v poriadku.
							</div>
						</div>
						<div class="col-12">
							<p>{{ticket.eventName}}: 
								<span v-for="item in ticket.items" v-bind:key="item">{{item.title}}, </span>
							</p>
						</div>
						
						<div class="col-12">
							<div class="my-body" id="printArea">
								<div class="title">	
									<p class="title-text"><br><b>{{ticket.firstname}} {{ticket.surname}}</b>
									</p>
								</div>			
								
								
							</div>
						</div>
						
						<div class="col-6 box-flex">
							<button v-on:click="approveTicket()" class="btn btn-success btn-sm">{{ $t('message.Approve') }}</button>
							
						</div>
						
						<div class="col-6 box-flex">
						
							<button v-on:click="printLabel()" class="btn btn-success btn-sm">{{ $t('message.PrintLabel') }}</button>
						</div>
						
					</div>	
					
					
					<div v-if="resCode==5" class="row">
						<div class="col-12">
							<div class="alert alert-warning" role="alert">
								Lístok nie je v poriadku, ju určený pre inú konferenciu.
							</div>
						</div>
						<div class="col-12">
							<p>{{ticket.eventName}}: 
								<span v-for="item in ticket.items" v-bind:key="item">{{item.title}}, </span>
							</p>
						</div>
						
						<div class="col-12">
							<p>{{ticket.titleBefore}} {{ticket.surname}} {{ticket.firstname}} {{ticket.titleAfter}}, {{ticket.company}}, {{ticket.position}}
							</p>
						</div>
						
						<div class="col-6 box-flex">
							
						</div>
						<div class="col-6 box-flex">
							<button v-on:click="closeModalBoxSecure('myModalTicket')" class="btn btn-light btn-sm">{{ $t('message.Close') }}</button>
							
						</div>
					</div>
					
					
					
					
					
					<div v-if="resCode==4" class="row">
						<div class="col-12">
							<div class="alert alert-danger" role="alert">
								Pre lístok neexistuje event s osobnou účasťou.
							</div>
						</div>
						
						<div class="col-6 box-flex">
						</div>
						<div class="col-6 box-flex">
							<button v-on:click="closeModalBoxSecure('myModalTicket')" class="btn btn-light btn-sm">{{ $t('message.Close') }}</button>
						</div>
					</div>
					
					<div v-if="resCode==3" class="row">
						<div class="col-12">
							<div class="alert alert-danger" role="alert">
								Lístok bol stornovaný.
							</div>
						</div>
						
						<div class="col-12">
							<p><b>Dátum a čas storna lístka:</b> {{ticket.canceled_dateView}}</p>
							<p><b>Dôvod storna:</b> {{ticket.canceled_reason}}</p>
						</div>
						
						<div class="col-6 box-flex">
						</div>
						<div class="col-6 box-flex">
							<button v-on:click="closeModalBoxSecure('myModalTicket')" class="btn btn-light btn-sm">{{ $t('message.Close') }}</button>
						</div>
					</div>	
					
					<div v-if="resCode==2" class="row">
						<div class="col-12">
							<div class="alert alert-warning" role="alert">
								Pre vstupný kód neexistuje pridelená udalosť.
							</div>
						</div>
						
						<div class="col-6 box-flex">
						</div>
						<div class="col-6 box-flex">
							<button v-on:click="closeModalBoxSecure('myModalTicket')" class="btn btn-light btn-sm">{{ $t('message.Close') }}</button>
						</div>
					</div>
					
					
					<div v-if="resCode==0" class="row">
						<div class="col-12">
							<div class="alert alert-warning" role="alert">
								Vstupný kód je prázdny.
							</div>
						</div>
						
						<div class="col-6 box-flex">
						</div>
						<div class="col-6 box-flex">
							<button v-on:click="closeModalBoxSecure('myModalTicket')" class="btn btn-light btn-sm">{{ $t('message.Close') }}</button>
						</div>
					</div>
					
					<div v-if="resCode==6" class="row">
						<div class="col-12">
							<div class="alert alert-danger" role="alert">
								Lístok už bol raz vybavený.
							</div>
						</div>
						<div class="col-12">
							<p><b>Dátum a čas načítania lístka:</b> {{ticket.checked_dateView}}</p>
						</div>
						
						<div class="col-6 box-flex">
						</div>
						<div class="col-6 box-flex">
							<button v-on:click="closeModalBoxSecure('myModalTicket')" class="btn btn-light btn-sm">{{ $t('message.Close') }}</button>
						</div>
					</div>
					
				</div>
				
			</div>
		</div>
	</div>
	
		
	<section v-if="resCode==1">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div class="my-body" id="printArea2">
						<div class="title">	
							<span class="printed-text" :style="{fontSize:labelFontSize+'px'}"><b>{{ticketPrint.firstname}} {{ticketPrint.surname}}</b>
							</span>
						</div>
						<div class="company">	
							<span class="printed-text" :style="{fontSize:labelFontSizeCompany+'px'}"><b>{{ticketPrint.company}}</b>
							</span>
						</div>							
					</div>
				</div>
			</div>
		</div>
	</section>
	
	
</template>


