<script>

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import $ from 'jquery'; 

import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import sk from 'video.js/dist/lang/sk.json';

//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '../mixins/GeneralFunctions';

import axios from 'axios';

export default {
	name: 'LiveStream',
	mixins: [ MixinGeneralFunct ],
	components: {
		"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: ["myUserReg","myEventReg","myEventCounts","event"],
	data() {
		return {
			prefix:'',
						
			accData: [],
			secData: [],
			auth: [],
			menuItems: [],
			cookiesTime: 259200,
			
			eventIsRegistered:false,
			eventRegCode:'',
			selectedAccessCode:'',
			
			loadInterval:'',
			
			isLogged:true,
			
			question:[],
			questionEdit:[],
			myEvent:[],
			myUser:[],
			countCharQuestion:250,
			countCharQuestionEdit:250,
			countCharQuestionLimit:250,
			
			streamData: [],
			
			streamId:'',
			
			helpboxData: [],
			
			questionsCount:0,
			questions:[],
			sysQuestions:[],
			
			timeToRefresh:10000,
			
			firstLoading:true,
			tmpQuestions:[],
	
			countPages:0,
			selectedPage:null,
			selectedRecords:null,
			limit_from:0,
			limit_to:0,
			
			isSelectedQuestion:false,
			curSelectedQuestionID:'',
			
			selectedCol:'created',
			selectedSort:'desc',
		
			filterExtended:true,
			showFilter:false,
			
			questionsFullWidth:false,
			questionsOpened:false,
			
			options: {
				type: Object,
				default() {
					return {};
				}
			}
			
		}
	},
	
	mounted:function(){
		
		this.streamId = this.$route.params.id;
		
		var DcSortCol = this.getCookie("cSortCol");   
		if (DcSortCol != "") {
			this.selectedCol = DcSortCol;
		}
		
		var DcSortDir = this.getCookie("cSortDir");   
		if (DcSortDir != "") {
			this.selectedSort = DcSortDir;
		}
		
		var eventReg = this.getCookie("eventReg");   
		if (eventReg == 1) {
			this.eventIsRegistered = true;
		}
		
		this.loadDefaultQuestion();
		//if(this.eventIsRegistered){
			this.loadQuestions();
			this.loadInterval = setInterval(this.loadQuestions, this.timeToRefresh);
			this.loadLiveStreams();
			
			//self.loadQuestions();
			//			self.loadLiveStreams();
			//			self.resetLoadingInterval();
			
		//}	

		
			this.loadQuestions();
			this.loadInterval = setInterval(this.loadQuestions, this.timeToRefresh);
			this.loadLiveStreams();
		
		
	},
	
	methods: {
		
		runVideo:function(strKey){
			
			
			var myVideoObjectWidth = document.getElementById("my-player-content").offsetWidth;
			
			var mySrc = "https://meeting.pp.sk/"+strKey+".m3u8";
			
			var self = this;
			$.get(mySrc).done(function(){
				
				$('#my-video').show();
				$('#offline-warning').hide();
	
				var options = {width:myVideoObjectWidth-30,crossOrigin:'Anonymous',height:'auto',sources:[{src: mySrc,type: 'application/x-mpegURL'}],language:self.$i18n.locale,languages:{sk},responsive:true,controls:true,preload:'auto',poster:'../images/poster.jpg'};
				
				//odstranim objekt s videom
				var el = document.getElementById('myPlayer2020');
				videojs(el).dispose();
				
				//pridam objekt video
				var x = '<video	id="myPlayer2020"	class="video-js vjs-default-skin vjs-big-play-centered"></video>';
				$( '#my-video' ).append(x);
				
				self.player = videojs('myPlayer2020', options, function onPlayerReady(){
					//player.language(self.$i18n.locale);
				});
			
			}).fail(function (){
				$('#my-video').hide();
				$('#offline-warning').show();
			});
			
			
			
		},
			
		//default data
		loadDefaultQuestion:function(){
			var self = this;
			axios.get('/app/includeData/Questions/getDefaultData.php')
			.then(function (response) {
				//console.log("loadDefData");
				//console.log(response.data);
				
				//hide loader
				$('#preloader').fadeOut(200);
				
				self.question = response.data.question;
				
				var x = self.getCookie("creatorName");   
				if (x) {
					self.question.creatorName = x;
				}
			})
				.catch(function (error) {
				console.log(error);
			});
		},
		
		
		//natiahnutie streamu
		loadLiveStreams:function(){
						
			var self = this;
			axios.get('/app/includeData/Stream/getStreamData.php',{
				params:{
					id:this.streamId,
				}
			})
			.then(function (response) {
				//console.log("loadLiveStreams");
				//console.log(response.data);
				
				self.streamData = response.data.streamData;
				
				if(self.streamData.streamKey && self.streamData.streamKey != 'null'){
					setTimeout(self.runVideo, 300,self.streamData.streamKey);
				}
				
				/*if(self.streamData.countRecords>0){
					for(var i=0;i<self.recPlayer.length;i++){
						setTimeout(self.runRecord, 300,self.streamData.arrRecords[i]);
					}
				}
				
				for(var i=0;i<self.recPlayer.length;i++){
					setTimeout(self.runRecord, 300,self.streamData.arrRecords[i]);
				}
				.dispose()*/
				
				
			})
				.catch(function (error) {
				console.log(error);
			});
		},
		
		
		//zoznam otazok
		loadQuestions:function(){
			
			//1.krok novych otazok
			this.tmpQuestions=[];
			for(let i = 0; i < this.questions.length; i++){
				this.tmpQuestions.push(this.questions[i].id);
			}
			//console.log("povodne");
			//console.log(this.tmpQuestions);
			
		//	var c = this.getCookie("eventRegCode");
			
			var self = this;
			axios.get('/app/includeData/Questions/getQuestionsData.php',{
				params:{
					//code:c,
					//selectedIds: this.selectedIds
				}
			})
			.then(function (response) {
				//console.log("loadQuestions");
				//console.log(response.data);
				
				self.questionsCount = response.data.basicDataCount;
				self.questions = response.data.basicData;
				self.sysQuestions = response.data.systemData;
				
				self.myEvent = response.data.myEvent;
				self.myUser = response.data.myUser;
				
				//self.isLogged = response.data.isLogged;
				
				//if(!self.isLogged){
				//	self.logoutEvent();
				//	setTimeout(function(){ window.location.href = window.location.protocol+'//'+window.location.hostname+'/'; }, 300);
				//}
				
				//2.krok novych otazok
				if(!self.firstLoading){
					for(let i = 0; i < self.questions.length; i++){
						if(self.tmpQuestions.indexOf(self.questions[i].id) == -1){
							self.questions[i].isNewQuestion=true;
						}
					}
					//console.log("nove pole");
					//console.log(app.questions);
				}
				self.firstLoading=false;
				
				self.isSelectedQuestion = response.data.isSelectedQuestion;
				self.curSelectedQuestionID = response.data.curSelectedQuestionID;
				
				//oznacenie poloziek cez strankovac
				/*self.selectedIdsTEMP = response.data.selectedIds; 
				self.selectedIds = [];
				for(let i = 0; i < self.selectedIdsTEMP.length; i++){
					self.selectedIds.push(Number(self.selectedIdsTEMP[i]));
				}
				for(let i = 0; i < self.basicData.length; i++){
					var indexToSelect = self.selectedIds.indexOf(self.basicData[i].id);
					if(indexToSelect>=0){
						self.basicData[i].selectedId=true;
					}
				}
				self.selectAllIds = false;*/
				
				
			})
				.catch(function (error) {
				console.log(error);
			});
		},
		
		//registracia event
		//presunuta
		regEvent:function(){
			if(this.selectedAccessCode){
			
				var self = this;
				axios.get('/app/includeData/Questions/controlEventReg.php',{
					params:{
						selectedAccessCode:this.selectedAccessCode
					}
				})
				.then(function (response) {
					
					//console.log(response.data);
					if(response.data.resCode==1){
						self.setCookie("eventRegCode", self.selectedAccessCode, self.cookiesTime);
						self.setCookie("eventReg", 1, self.cookiesTime);
						self.eventIsRegistered=true;
						self.loadQuestions();
						self.loadLiveStreams();
						self.resetLoadingInterval();
					}else if(response.data.resCode==2){
						self.createMessageBoxTrans('warning','Event','udalostPreZadanyKlucNeexistuje');
					}
										
				})
					.catch(function (error) {
					console.log(error);
				});
			
			}
		},
		
		checkSave:function(){
			if(!this.question.title){this.question.titleError = true; }else{this.question.titleError = false;}
			
			if(this.question.title){
				return true;
			}else{
				this.createMessageBoxTrans('warning','Question','placeQuestion');
				return false;
			}
		},
		
		checkSaveEdit:function(){
			if(!this.questionEdit.title){this.questionEdit.titleError = true; }else{this.questionEdit.titleError = false;}
			
			if(this.questionEdit.title){
				return true;
			}else{
				this.createMessageBoxTrans('warning','Question','placeQuestion');
				return false;
			}
		},	
		
		//add question
		newQuestion:function(){
			if(this.checkSave()){
				var bodyFormData = new FormData();
				this.question.eventId=this.myEvent.id;
				this.question.state=this.myEvent.DDQ;
				var dataQuestion = JSON.stringify(this.question);
				bodyFormData.set('myQuestion',dataQuestion);
				
				var self = this;
				axios({
					method: 'post',
					url: '/app/includeData/Questions/controlAddQuestion.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data.resCode==1){
							self.loadQuestions();
							self.createMessageBoxTrans('success','Question','questionWasAdded');
							self.setCookie("creatorName", self.question.creatorName, self.cookiesTime);
							self.question.title = '';
							self.countCharQuestion = self.countCharQuestionLimit;
							self.closeModalBoxSecure('myModalNewQuestion');
							self.resetLoadingInterval();
						}else if(response.data.resCode==0){
							self.createMessageBoxTrans('warning','Question','dataCantBeSaved');
						}else if(response.data.resCode==2){
							self.createMessageBoxTrans('danger','Question','dataWasNotSaved');
						}else if(response.data.resCode==3){
							self.createMessageBoxTrans('danger','Question','accessDenied');
						}
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
			}
		},
		
		//save question
		saveQuestion:function(){
			if(this.checkSaveEdit()){
				var bodyFormData = new FormData();
				var dataQuestion = JSON.stringify(this.questionEdit);
				bodyFormData.set('myQuestion',dataQuestion);
				
				var self = this;
				axios({
					method: 'post',
					url: '/app/includeData/Questions/controlSaveQuestion.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data.resCode==1){
							self.loadQuestions();
							self.createMessageBoxTrans('success','Question','questionWasSaved');
							self.setCookie("creatorName", self.questionEdit.creatorName, self.cookiesTime);
							self.questionEdit.title = '';
							self.countCharQuestion = self.countCharQuestionLimit;
							self.closeModalBoxSecure('myModalEditQuestion');
							self.resetLoadingInterval();
						}else if(response.data.resCode==0){
							self.createMessageBoxTrans('warning','Question','dataCantBeSaved');
						}else if(response.data.resCode==2){
							self.createMessageBoxTrans('danger','Question','dataWasNotSaved');
						}else if(response.data.resCode==3){
							self.createMessageBoxTrans('danger','Question','accessDenied');
						}
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
			}
		},
		
		switchLike:function(idQuestion,b){
			if(b){
				this.removeLike(idQuestion);
			}else{
				this.addLike(idQuestion);
			}
		},
		
		//add like
		addLike:function(idQuestion){
			
			if(idQuestion){
				
				var bodyFormData = new FormData();
				bodyFormData.set('questionId',idQuestion);
				
				var self = this;
				axios({
					method: 'post',
					url: '/app/includeData/Questions/controlLikeQuestion.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data.resCode==1){
							self.loadQuestions();
							self.createMessageBoxNoTrans('success','Question','OK');
							self.resetLoadingInterval();
						}else if(response.data.resCode==0){
							self.createMessageBoxTrans('warning','Question','recordCantBeSaved');
						}else if(response.data.resCode==2){
							self.createMessageBoxTrans('danger','Question','recordWasNotSaved');
						}else if(response.data.resCode==3){
							self.createMessageBoxTrans('danger','Question','accessDenied');
						}else if(response.data.resCode==4){
							self.createMessageBoxTrans('danger','Question','likeUzRazPridanyBol');
						}
						
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
			}
			
		},
		
		//remove like
		removeLike:function(idQuestion){
			
			if(idQuestion){
				
				var bodyFormData = new FormData();
				bodyFormData.set('questionId',idQuestion);
				
				var self = this;
				axios({
					method: 'post',
					url: '/app/includeData/Questions/controlRemoveLikeQuestion.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data.resCode==1){
							self.loadQuestions();
							self.createMessageBoxNoTrans('success','Question','OK');
							self.resetLoadingInterval();
						}else if(response.data.resCode==0){
							self.createMessageBoxTrans('warning','Question','recordCantBeSaved');
						}else if(response.data.resCode==2){
							self.createMessageBoxTrans('danger','Question','recordWasNotSaved');
						}else if(response.data.resCode==3){
							self.createMessageBoxTrans('danger','Question','accessDenied');
						}
						
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
			}
			
		},
		
		
		//remove question
		removeQuestion:function(idQuestion){
			
			if(idQuestion){
				
				var bodyFormData = new FormData();
				bodyFormData.set('questionId',idQuestion);
				bodyFormData.set('curSelectedQuestionID',this.curSelectedQuestionID);
				bodyFormData.set('eventId',this.myEvent.id);
			
				var self = this;
				axios({
					method: 'post',
					url: '/app/includeData/Questions/controlRemoveQuestion.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data.resCode==1){
							self.loadQuestions();
							self.createMessageBoxTrans('success','Question','questionWasRemoved');
							self.closeModalBoxSecure('myModalRemoveQuestion');
							self.resetLoadingInterval();
						}else if(response.data.resCode==0){
							self.createMessageBoxTrans('warning','Question','questionCantBeRemoved');
						}else if(response.data.resCode==2){
							self.createMessageBoxTrans('danger','Question','questionWasNotRemoved');
						}else if(response.data.resCode==3){
							self.createMessageBoxTrans('danger','Question','accessDenied');
						}
						
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
			}
			
		},
		
		//zmena stavu
		changeState:function(recordID,state){
						
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',recordID);
			bodyFormData.set('state',state);
			bodyFormData.set('curSelectedQuestionID',this.curSelectedQuestionID);
			bodyFormData.set('eventId',this.myEvent.id);
			
			var self = this;
			axios({
				method: 'post',
				url: '/app/includeData/Questions/controlQuestionChangeState.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
				//	console.log('success2', response.data);
					if(response.data.resCode==1){
						self.createMessageBoxNoTrans('success','Question','OK');
						self.loadQuestions();
					}else if(response.data.resCode==0){
						self.createMessageBoxTrans('warning','Question','dataCantBeSaved');
					}else if(response.data.resCode==2){
						self.createMessageBoxTrans('danger','Question','dataWasNotSaved');
					}else if(response.data.resCode==3){
						self.createMessageBoxTrans('danger','Question','accessDenied');
					}
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
	
		},
		
		//zobrazenie otazok do diskusie
		showHideDiscuss:function(){
			if(this.questionsOpened){
				this.questionsOpened=false;
			}else{
				this.questionsOpened=true;
			}
		},
		
		//get count chars
		getCountChars:function(){
			this.countCharQuestion=this.countCharQuestionLimit - this.question.title.length;
			if(this.countCharQuestion<0){this.question.countCharError = true; }else{this.question.countCharError = false;}
		},
		
		//get count chars edit
		getCountCharsEdit:function(){
			this.countCharQuestionEdit=this.countCharQuestionLimit - this.questionEdit.title.length;
			if(this.countCharQuestionEdit<0){this.questionEdit.countCharError = true; }else{this.questionEdit.countCharError = false;}
		},
		
		
		
		//reset loading interval
		resetLoadingInterval:function(){
			clearInterval(this.loadInterval);
			this.loadInterval = setInterval(this.loadQuestions,  this.timeToRefresh);
		},
		
		//(od)oznacenie vsetkych poloziek
		clearSelectedQuestion:function(){
			this.isSelectedQuestion = false;
			this.curSelectedQuestionID = '';
			
			var bodyFormData = new FormData();
			bodyFormData.set('eventId',this.myEvent.id);
			
			var self = this;
			axios({
				method: 'post',
				url: '/app/includeData/Questions/controlResetSelectedQuestion.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log('success2', response.data);
					if(response.data.resCode==1){
						//self.createMessageBoxNoTrans('success','Question','OK');
						self.loadQuestions();
						
					}else if(response.data.resCode==0){
						self.createMessageBoxTrans('warning','Question','dataCantBeSaved');
					}else if(response.data.resCode==2){
						self.createMessageBoxTrans('danger','Question','dataWasNotSaved');
					}else if(response.data.resCode==3){
						self.createMessageBoxTrans('danger','Question','accessDenied');
					}
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
		},
		
		//oznacenie jednej polozky
		saveSelectedQuestion:function(idQuestion){
			
			var bodyFormData = new FormData();
			bodyFormData.set('idQuestion',idQuestion);
			bodyFormData.set('eventId',this.myEvent.id);
			
			var self = this;
			axios({
				method: 'post',
				url: '/app/includeData/Questions/controlSetSelectedQuestion.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log('success2', response.data);
					if(response.data.resCode==1){
						//self.createMessageBoxNoTrans('success','Question','OK');
						self.loadQuestions();
						self.isSelectedQuestion = true;
						self.curSelectedQuestionID = idQuestion;
					}else if(response.data.resCode==0){
						self.createMessageBoxTrans('warning','Question','dataCantBeSaved');
					}else if(response.data.resCode==2){
						self.createMessageBoxTrans('danger','Question','dataWasNotSaved');
					}else if(response.data.resCode==3){
						self.createMessageBoxTrans('danger','Question','accessDenied');
					}
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
		},
		
		//radenie zoznamu
		sortMe:function(s,t) {
			//if s == current sort, reverse
			//if(s === this.selectedCol) {
			//this.selectedSort = 'desc';
			//  this.selectedSort = this.selectedSort==='asc'?'desc':'asc';
			//}
			this.selectedCol = s;
			this.selectedSort = t;
			
			this.setCookie("cSortCol", s, this.cookiesTime);
			this.setCookie("cSortDir", t, this.cookiesTime);
		},
		
		//messagebox v1.1
		createMessageBoxNoTrans: function(kind,object,message) {
			var content = "";
			content += "<div class='alert alert-"+kind+"' role='alert'>";
			content += message;
			content += "</div>";
			
			var rand = this.getRandomInt(600000);
			$('#msg').append("<div class='msg"+rand+"'></div>");
			$('.msg'+rand).html("").append(content).fadeIn(100).delay(6000).fadeOut(300);
		},
		
		//messagebox v1.1
		createMessageBoxTrans: function(kind,object,message) {
			var content = "";
			content += "<div class='alert alert-"+kind+"' role='alert'>";
			content += this.$t('message.'+message);
			content += "</div>";
			
			var rand = this.getRandomInt(600000);
			$('#msg').append("<div class='msg"+rand+"'></div>");
			$('.msg'+rand).html("").append(content).fadeIn(100).delay(6000).fadeOut(300);
		},
		
		//modal new question
		loadModalNewQuestion:function(){
			
			var self = this;
			axios({
				method: 'post',
				url: '/app/includeData/Questions/getDefaultData.php'
			})
			.then(response => {
				if (response.data.error) {
					console.log('error', response.data.error)
				} else {
					//console.log('success2', response.data);
					self.question = response.data.question;
					self.countCharQuestion=self.countCharQuestionLimit - self.question.title.length;
					$('#myModalNewQuestion').addClass('modal');
					$('#myModalNewQuestion').modal({backdrop: "static"});
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
		},
		
		//modal edit question
		loadModalEditQuestion:function(idQuestion){
			
			var bodyFormData = new FormData();
			bodyFormData.set('questionId',idQuestion);
			
			var self = this;
			axios({
				method: 'post',
				url: '/app/includeData/Questions/getQuestionData.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log('success2', response.data);
					if(response.data.resCode==1){
						self.questionEdit = response.data.question;
						self.countCharQuestionEdit=self.countCharQuestionLimit - self.questionEdit.title.length;
						$('#myModalEditQuestion').addClass('modal');
						$('#myModalEditQuestion').modal({backdrop: "static"});
					}else if(response.data.resCode==3){
						self.createMessageBoxTrans('danger','Question','accessDenied');
					}
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
		},
		
		//modal remove question
		loadModalRemoveQuestion:function(idQuestion){
			
			var bodyFormData = new FormData();
			bodyFormData.set('questionId',idQuestion);
			
			
			var self = this;
			axios({
				method: 'post',
				url: '/app/includeData/Questions/getQuestionData.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log('success2', response.data);
					if(response.data.resCode==1){
						self.questionEdit = response.data.question;
						$('#myModalRemoveQuestion').addClass('modal');
						$('#myModalRemoveQuestion').modal({backdrop: "static"});
					}else if(response.data.resCode==3){
						self.createMessageBoxTrans('danger','Question','accessDenied');
					}
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
		},
		
		// zatvaranie modalbox
		closeModalBoxSecure:function(myform){
			this.countCharQuestionEdit = this.countCharQuestionLimit;
			this.countCharQuestion = this.countCharQuestionLimit;
			$("#"+myform).modal("hide");
			$("#"+myform).removeClass("modal");
		}
				
	},	
	
			
	watch: {
		/*myUserReg() {
			this.loadQuestions();
		},
		myEventReg() {
			this.loadLiveStreams();
		}*/
	},

	computed:{
		sortedBasicData:function() {
			//if(this.questions){
			return this.questions.slice().sort((a,b) => {
			let modifier = 1;
			if(this.selectedSort === 'desc') modifier = -1;
			if(a[this.selectedCol] < b[this.selectedCol]) return -1 * modifier;
			if(a[this.selectedCol] > b[this.selectedCol]) return 1 * modifier;
			return 0;
			});
			//}
		},
	},
	
}






</script>

<template>
	
	<div id="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
	
	<div id="msg"></div>
	<div id="msgForm"></div>

	
		
	<section v-if="streamData.resCode==1" id="videostream">
		<div class="container">
			
			<div class="row">
				<div class="col-12">
					<h4>{{ streamData.title }}</h4>
				</div>
			</div>
			
			<div class="row">
				
			<div id="box-stream" class="col-12 col-md-8 offset-md-2">	
					
				<div class="row">	
					<div class="col-12" id="my-player-content">
						
						<div id="my-video" style="display:none;">
							<video id="myPlayer2020" class="video-js vjs-default-skin vjs-big-play-centered"/>
						</div>
						
						<div id="offline-warning" class="text-center">
							<p>{{ $t('message.'+streamData.msg1) }} {{ streamData.msgTime }}</p>
							<p>{{ $t('message.'+streamData.msg2) }}</p>
						</div>
					</div>
					
					
					<div v-if="myEvent.access_code && streamData.isStreamingTime" id="box-chat" class="col-12 chat-bck" :class="{'full':questionsFullWidth,'opened':questionsOpened}">
					
						<div class="button-close" v-on:click="showHideDiscuss()"><font-awesome-icon :icon="['fas', 'times']" /></div>
						
						<div class="button-full-width">
							<span class="togglebutton">
								<label>
									<input v-model='questionsFullWidth' type="checkbox" >
									<span class="toggle"></span>
									<br><span class="button-subtitle">{{ $t('message.fullWidth') }}</span>
								</label>
							</span>
						</div>
								
						<div class="openQuestionBtn box-flex" v-on:click="showHideDiscuss()"><font-awesome-icon :icon="['fas', 'comment']" />&nbsp;&nbsp;{{ $t('message.QuestionsToDiscuss') }}</div>
						
						<div v-on:click="loadModalNewQuestion()" :title="$t('message.AddQuestion')" class="addQuestionBtn box-flex"><font-awesome-icon :icon="['fas', 'pen']" /></div>
					
					
						<div class="box-chat-content">
						
								
								
						
								<section id="obsah">
									
									<div class="row">
									
									<div class="col-12 chat-bck">
											
												<div v-if="!myUser.isRegistered" class="row">
													<div class="col-12">
														
														<div class="row">
															<div class="col-12 row-question">
																
															
																<div class="row">
																	<div class="form-group form-group-no-margin col-md-12 col-12">
																		<textarea class="form-control form-control-sm" :class="{'is-invalid':question.titleError}" rows="3" v-model="question.title" v-on:keyup="checkSave(),getCountChars()" :placeholder="$t('message.hereWriteYourQuestion')" ></textarea>
																		
																	</div>
																</div>
																
																<div class="row">
																	<div class="col-8">
																		<span v-show="question.countCharError" class="error-alert-text text-danger">{{ $t('message.TheNumberOfcharacsIsTooLarge') }}</span>
																		<span v-show="question.titleError" class="error-alert-text text-danger">{{ $t('message.EnterQuestion') }}</span>
																	</div>
																	<div class="col-4 text-right">
																		<span :class="{'text-danger':countCharQuestion<0}">{{countCharQuestion}}</span>
																	</div>
																</div>
																
																<div class="row">
																	<div class="col-md-8 col-8">
																		<input v-model="question.creatorName" class="form-control form-control-sm" :placeholder="$t('message.yourName')" >
																	</div>
																	<div class="col-md-4 col-4 text-center">
																		<button :disabled="question.countCharError || question.titleError" v-on:click="newQuestion()" class="btn btn-success btn-sm">{{ $t('message.Send') }}</button>
																	</div>
																	
																</div>
																
																
															</div>
														</div>
														
													</div>
												</div>
												
											

												
												<div v-if="myUser.isRegistered && isSelectedQuestion" class="row">
													<div class="col-12 text-right">
														<span v-on:click="clearSelectedQuestion()" class="btn btn-sm btn-warning">{{ $t('message.CancelSelectedQuestion') }} </span>
													</div>
												</div>
												
												<div  class="row">
													<div class="col-12">			
														
														<div class="row">
															<div class="col-6 col-md-6 row-menu-radenie">
																<div v-if="questions.length>0">
																	<span class="sort" :class="{'active':selectedCol=='created' && selectedSort=='desc'}" v-on:click="sortMe('created','desc')">{{ $t('message.TheLatest') }}</span> <span class="sort" :class="{'active':selectedCol=='created' && selectedSort=='asc'}" v-on:click="sortMe('created','asc')">{{ $t('message.TheOldest') }}</span> <span class="sort" :class="{'active':selectedCol=='liked' && selectedSort=='desc'}" v-on:click="sortMe('liked','desc')">{{ $t('message.Popular') }}</span>
																</div>
															</div>
															
															<div class="col-6 col-md-6 row-menu-radenie text-right">
																<span class="q-count">{{ $t('message.countQuestions') }}: {{questions.length}}</span>
															</div>
														</div>
														
														<div class="row">
															<div v-for="(item) in sysQuestions" v-bind:key="item"  class="col-12 row-question" :class="{'new':item.isNewQuestion,'systemMsg':item.systemMsg==1}">
																
																<div v-if="item.state==2" class="row">
																	<div class="col-12">
																		<p class="q-unpublished">{{ $t('message.Unpublished') }}</p>
																	</div>
																</div>
																
																<div class="row">
																	<div class="col-12">
																		<p>{{item.title}}</p>
																	</div>
																</div>
																
																<div class="row">
																	<div class="col-7 col-md-6">
																		<span class="q-creator" :class="{'anonym':item.creatorName=='Anonym'}"><b>{{item.creatorName}}</b></span>, <span class="q-time"><span v-if="item.diffTimeName!='nowTime'">{{ $t('message.before') }}</span> {{ $tc('message.'+item.diffTimeName,item.diffTime) }}</span>
																	</div>
																	<div class="col-5 col-md-6 text-right">
																	
																		<span v-if="item.isAllowedChangeState" class="togglebutton">
																			<label>
																				<input v-model='item.isActive' type="checkbox" v-on:change="changeState(item.id,item.state)">
																				<span class="toggle"></span>
																			</label>
																		</span>
																	
																		<span v-if="item.myQuestion && item.isAllowedEdit" class="q-edit" v-on:click="loadModalEditQuestion(item.id)" :title="$t('message.Edit')"><font-awesome-icon :icon="['fas', 'edit']" /></span>
																		<span v-if="item.myQuestion && item.isAllowedEdit" class="q-remove" v-on:click="loadModalRemoveQuestion(item.id)" :title="$t('message.Remove')"><font-awesome-icon :icon="['fas', 'trash-alt']" /></span>
																		<span class="q-like" :class="{'my-like':item.isMyLike}" v-on:click="switchLike(item.id,item.isMyLike)" ><font-awesome-icon :icon="['fas', 'thumbs-up']" /> {{item.liked}}</span>
																		
																	</div>
																</div>
																
																
															</div>
														</div>
														
														<div class="row">
															<div v-for="(item) in sortedBasicData" v-bind:key="item"  class="col-12 row-question" :class="{'new':item.isNewQuestion,'published':item.state==1,'unpublished':item.state==2,'selectedQ':item.selected==1}">
																
																<div v-if="item.state==2" class="row">
																	<div class="col-12">
																		<p class="q-unpublished">{{ $t('message.Unpublished') }}</p>
																	</div>
																</div>
																
																<div class="row">
																	<div class="col-12">
																	
																		
																	
																		<p>{{item.title}}</p>
																	</div>
																</div>
																
																<div class="row">
																	<div class="col-7 col-md-6">
																		<span class="q-creator" :class="{'anonym':item.creatorName=='Anonym'}"><b>{{item.creatorName}}</b></span>, <span class="q-time"><span v-if="item.diffTimeName!='nowTime'">{{ $t('message.before') }}</span> {{ $tc('message.'+item.diffTimeName,item.diffTime) }}</span>
																	</div>
																	<div class="col-5 col-md-6 text-right">
																	
																		<span v-if="item.isAllowedChangeState" class="togglebutton">
																			<label>
																				<input v-model='item.isActive' type="checkbox" v-on:change="changeState(item.id,item.state)">
																				<span class="toggle"></span>
																			</label>
																		</span>
																	
																		<span v-if="item.myQuestion && item.isAllowedEdit" class="q-edit" v-on:click="loadModalEditQuestion(item.id)" :title="$t('message.Edit')"><font-awesome-icon :icon="['fas', 'edit']" /></span>
																		<span v-if="item.myQuestion && item.isAllowedEdit" class="q-remove" v-on:click="loadModalRemoveQuestion(item.id)" :title="$t('message.Remove')"><font-awesome-icon :icon="['fas', 'trash-alt']" /></span>
																		<span class="q-like" :class="{'my-like':item.isMyLike}" v-on:click="switchLike(item.id,item.isMyLike)" ><font-awesome-icon :icon="['fas', 'thumbs-up']" /> {{item.liked}}</span>
																		<span class="q-active">
																		<div v-if="item.isAllowedChangeState" class="form-check">
																			<label class="form-check-label activeDesc">
																				<input v-model='item.selectedQuestionID' class="form-check-input" name='selectedQ' type="radio" :value="item.id" v-on:change="saveSelectedQuestion(item.id);">
																				{{ $t('message.Active') }}
																				<span class="circle">
																					<span class="check"></span>
																				</span>
																			</label>
																		</div>
																		</span>
																	</div>
																</div>
																
																
															</div>
														</div>
														
													</div>
												</div>
												
									</div>
									
									</div>
								</section>
						
						</div>	
					
					</div>
				
				</div>
			</div>
			</div>	
		</div>
	</section>	
	
	
	<section v-else-if="streamData.resCode==2">
		<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Pre sledovanie živého vysielania musíte byť prihlásený</h5>
				</div>
			</div>
		</div>
	</section>
		
	<section v-else-if="streamData.resCode==3">
		<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Chýbajú vstupné údaje</h5>
				</div>
			</div>
		</div>
	</section>
	
	<section v-else-if="streamData.resCode==4">
		<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Pre aktuálne živé vysielanie nemáte pridelené prístupové práva</h5>
				</div>
			</div>
		</div>
	</section>
	
	<section v-else-if="streamData.resCode==0">
		<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Vybrané živé vysielanie práve neprebieha</h5>
				</div>
			</div>
		</div>
	</section>
		
	
	
	
	<div id="myModalNewQuestion" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog" style="">
			<div class="modal-content">
				
				<div class="modal-header">
					<h6 class="modal-title"><span>{{ $t('message.yourQuestion') }}</span></h6>
					<div class="modal-button-close" v-on:click="closeModalBoxSecure('myModalNewQuestion')" ><font-awesome-icon :icon="['fas', 'times']" /></div>
					
				</div>
		
				<div class="modal-body">
					<div class="row">
						<div class="col-12">
						
						
							<div class="form-row">
								<div class="form-group form-group-no-margin col-md-12 col-12">
									<textarea class="form-control form-control-sm" :class="{'is-invalid':question.titleError}" rows="3" v-model="question.title" v-on:keyup="checkSave(),getCountChars()" :placeholder="$t('message.hereWriteYourQuestion')" ></textarea>
									
								</div>
							</div>
							
							<div class="row">
								<div class="col-8">
									<span v-show="question.countCharError" class="error-alert-text text-danger">{{ $t('message.TheNumberOfcharacsIsTooLarge') }}</span>
									<span v-show="question.titleError" class="error-alert-text text-danger">{{ $t('message.EnterQuestion') }}</span>
								</div>
								<div class="col-4 text-right">
									<span :class="{'text-danger':countCharQuestion<0}">{{countCharQuestion}}</span>
								</div>
							</div>
							
							<div class="row">
								<div class="col-md-8 col-8">
									<input v-model="question.creatorName" class="form-control form-control-sm" :placeholder="$t('message.yourName')" >
								</div>
								<div class="col-md-4 col-4 text-center">
									<button :disabled="question.countCharError || question.titleError" v-on:click="newQuestion()" class="btn btn-success btn-sm">{{ $t('message.Send') }}</button>
								</div>
								
							</div>
							
						
									
						</div>
					</div>	
				</div>
				
			</div>
		</div>
	</div>
	
	
	<div id="myModalEditQuestion" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog" style="">
			<div class="modal-content">
				
				<div class="modal-header">
					<h6 class="modal-title"><span>{{ $t('message.editOwnQuestion') }}</span></h6>
					<div class="modal-button-close" v-on:click="closeModalBoxSecure('myModalEditQuestion')" ><font-awesome-icon :icon="['fas', 'times']" /></div>
					
				</div>
		
				<div class="modal-body">
					<div class="row">
						<div class="col-12">
						
						
							<div class="form-row">
								<div class="form-group form-group-no-margin col-md-12 col-12">
									<textarea class="form-control form-control-sm" :class="{'is-invalid':questionEdit.titleError}" rows="3" v-model="questionEdit.title" v-on:keyup="checkSaveEdit(),getCountCharsEdit()" :placeholder="$t('message.hereWriteYourQuestion')" ></textarea>
									
								</div>
							</div>
							
							<div class="row">
								<div class="col-8">
									<span v-show="questionEdit.countCharError" class="error-alert-text text-danger">{{ $t('message.TheNumberOfcharacsIsTooLarge') }}</span>
									<span v-show="questionEdit.titleError" class="error-alert-text text-danger">{{ $t('message.EnterQuestion') }}</span>
								</div>
								<div class="col-4 text-right">
									<span :class="{'text-danger':countCharQuestionEdit<0}">{{countCharQuestionEdit}}</span>
								</div>
							</div>
							
							<div class="row">
								<div class="col-md-8 col-8">
									<input v-model="questionEdit.creatorName" class="form-control form-control-sm" :placeholder="$t('message.yourName')" >
								</div>
								<div class="col-md-4 col-4 text-center">
									<button v-if="questionEdit.id" :disabled="questionEdit.countCharError || questionEdit.titleError" v-on:click="saveQuestion()" class="btn btn-warning btn-sm">{{ $t('message.Save') }}</button>
								</div>
								
							</div>
							
						
									
						</div>
					</div>	
				</div>
				
			</div>
		</div>
	</div>
	
	<div id="myModalRemoveQuestion" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog" style="">
			<div class="modal-content">
				
				<div class="modal-header">
					<h6 class="modal-title"><span>{{ $t('message.doYouWantRealyRemoveQuestion') }}?</span></h6>
					<div class="modal-button-close" v-on:click="closeModalBoxSecure('myModalRemoveQuestion')" ><font-awesome-icon :icon="['fas', 'times']" /></div>
				</div>
		
				<div class="modal-body">
					<div class="row">
						<div class="col-12">
						
							<div class="row">
								<div class="col-md-12 col-12">
									{{questionEdit.title}}
								</div>
							</div>
							
							<div class="row">
								<div class="col-12 text-right">
									<button v-if="questionEdit.id" v-on:click="removeQuestion(questionEdit.id)" class="btn btn-danger btn-sm">{{ $t('message.Remove') }}</button>
								</div>
							</div>

						</div>
					</div>	
				</div>
				
			</div>
		</div>
	</div>
	
</template>


