<script>

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment, faCheckCircle, faCheck, faPrint, faPlus} from '@fortawesome/free-solid-svg-icons';
library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment, faCheckCircle, faCheck, faPrint, faPlus)
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
//, reactive, toRefs
import { defineComponent } from 'vue';
//import { QrStream } from 'vue3-qr-reader' //hl29
//import { QrcodeCapture } from 'vue3-qrcode-reader' //novy
// QrcodeStream
//import { QrCapture, QrStream } from 'vue3-qr-reader';
import $ from 'jquery'; 
//import from 'print-this'; 


//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '../mixins/GeneralFunctions';

import axios from 'axios';

export default defineComponent ({
	name: 'ManualChecking',
	mixins: [ MixinGeneralFunct ],
	components: {
		"font-awesome-icon":FontAwesomeIcon,
		//,"video-player":VideoPlayer
		//QrStream,
		//QrCapture,
		
		//QrcodeStream, //testuj
		//QrcodeCapture //testuj
		//QrDropzone
	},
	
	directives: {
		focus: {
			// directive definition
			mounted(el) {
				el.focus()
			}
		}
	},
	
	props: ["myUserReg","myEventReg","myEventCounts","event"],
	
	/*setup(props) {
		console.log(props);
		const state = reactive({
			qrCode: null
		})
		function onDecode(data) {
			state.qrCode = data
			console.log(data);
		}
		return {
			...toRefs(state),
			onDecode,
		}
	},*/
	
	data() {
		return {
			
			customersData: [],
			
			//alertsCount:0,
			ticket:[],
			ticketPrint:[],
			resCode:'',
			labelFontSize:26,
			labelFontSizeCompany:16,
			
			custAccess:[],
			optionsEventItems:[],
			
			searchTerm:''
			
						
		}
	},
	
	
	
	mounted:function(){
	
			this.loadDefaultData();
		
		//this.$refs.myinput.$el.focus()
		
	},
	
	methods: {
				
		//zoznam otazok
		loadDefaultData:function(){
			
			var self = this;
			axios.get('/app/includeData/customersList/getCustomersData.php',{
				params:{
					searchTerm: this.searchTerm,
					//selectedIds: this.selectedIds
				}
			})
			.then(function (response) {
				//console.log("loadDefaultData");
				//console.log(response.data);
				
				//hide loader
				$('#preloader').fadeOut(200);
				
				//self.alertsCount = response.data.basicDataCount;
				//self.alerts = response.data.basicData;
				self.customersData = response.data.customersData;
				
				//self.myUser = response.data.myUser;
			
			})
				.catch(function (error) {
				console.log(error);
			});
		},
		
		
		
		
		
		checkCode:function(code) {
			if(code){
				//console.log(code)
				
				
				
				var bodyFormData = new FormData();
				bodyFormData.set('code',code);
				
				var self = this;
				axios({
					method: 'post',
					url: '/app/includeData/customersList/getTicketData.php',
					data: bodyFormData
				})
				.then(response => {
					if(response.data.error){
							console.log('error', response.data.error)
					}else{
						//console.log(response.data);
						self.ticket=response.data.ticket;
						self.resCode=response.data.resCode;
						
						$('#myModalTicket').addClass('modal');
						$('#myModalTicket').modal({backdrop: "static"});
										
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
		
			}
			
		},
		
		
		printAgain:function(id) {
			if(id){
				
				var bodyFormData = new FormData();
				bodyFormData.set('customerId',id);
				
				var self = this;
				axios({
					method: 'post',
					url: '/app/includeData/customersList/getTicketDataByID.php',
					data: bodyFormData
				})
				.then(response => {
					if(response.data.error){
							console.log('error', response.data.error)
					}else{
						//console.log(response.data);
						self.ticket=response.data.ticket;
						self.resCode=response.data.resCode;
						
						$('#myModalPrintTicketAgain').addClass('modal');
						$('#myModalPrintTicketAgain').modal({backdrop: "static"});
										
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
		
			}
			
		},
		
		//messagebox v1.1
		createMessageBoxNoTrans: function(kind,object,message) {
			var content = "";
			content += "<div class='alert alert-"+kind+"' role='alert'>";
			content += message;
			content += "</div>";
			
			var rand = this.getRandomInt(600000);
			$('#msg').append("<div class='msg"+rand+"'></div>");
			$('.msg'+rand).html("").append(content).fadeIn(100).delay(6000).fadeOut(300);
		},
		
		//messagebox v1.1
		createMessageBoxTrans: function(kind,object,message) {
			var content = "";
			content += "<div class='alert alert-"+kind+"' role='alert'>";
			content += this.$t('message.'+message);
			content += "</div>";
			
			var rand = this.getRandomInt(600000);
			$('#msg').append("<div class='msg"+rand+"'></div>");
			$('.msg'+rand).html("").append(content).fadeIn(100).delay(6000).fadeOut(300);
		},
		
		// zatvaranie modalbox
		closeModalBoxSecure:function(myform){
			$("#"+myform).modal("hide");
			$("#"+myform).removeClass("modal");
			
			
		},
		
		
		
			
		//schvalenie listka
		approveTicket:function(){
			if(this.ticket.id){
				console.log(this.ticket.id);
				var bodyFormData = new FormData();
				bodyFormData.set('custAccessId',this.ticket.id);
				
				var self = this;
				axios({
					method: 'post',
					url: '/app/includeData/customersList/controlApprove.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('approveTicket', response.data);
						if(response.data.resCode==1){
							self.createMessageBoxNoTrans('success','Ticket','OK');
							self.closeModalBoxSecure('myModalTicket')
							self.loadDefaultData();
						}else if(response.data.resCode==0){
							self.createMessageBoxTrans('warning','Ticket','dataCantBeSaved');
						}else if(response.data.resCode==2){
							self.createMessageBoxTrans('danger','Ticket','dataWasNotSaved');
						}else if(response.data.resCode==3){
							self.createMessageBoxTrans('danger','Ticket','accessDenied');
						}
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
			
			}
	
		},
		
		//tlac listka
		printLabel:function(){
			if(this.ticket.id){
				this.ticketPrint=this.ticket;
				
				//kontrola dlzky text a set velkosti
				this.setFontSizeLabel();
				
				//console.log(this.ticket.id);
				var bodyFormData = new FormData();
				bodyFormData.set('custAccessId',this.ticket.id);
				
				var self = this;
				axios({
					method: 'post',
					url: '/app/includeData/customersList/controlApprove.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('approveTicket', response.data);
						if(response.data.resCode==1){
							self.createMessageBoxNoTrans('success','Ticket','OK');
							$('#printArea2').printThis({
								importStyle: true
							});	
							self.loadDefaultData();
							$("#myModalTicket").modal("hide");
							$("#myModalTicket").removeClass("modal");
						
						}else if(response.data.resCode==0){
							self.createMessageBoxTrans('warning','Ticket','dataCantBeSaved');
						}else if(response.data.resCode==2){
							self.createMessageBoxTrans('danger','Ticket','dataWasNotSaved');
						}else if(response.data.resCode==3){
							self.createMessageBoxTrans('danger','Ticket','accessDenied');
						}
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
			
			}
	
		},
		
		
		//opatovna tlac listka
		printLabelAgain:function(){
			if(this.ticket.id){
				this.ticketPrint=this.ticket;
				
				//kontrola dlzky text a set velkosti
				this.setFontSizeLabel();
				
				var bodyFormData = new FormData();
				bodyFormData.set('custAccessId',this.ticket.id);
				
				var self = this;
				axios({
					method: 'post',
					url: '/app/includeData/customersList/controlPrintAgain.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('approveTicket', response.data);
						if(response.data.resCode==1){
							self.createMessageBoxNoTrans('success','Ticket','OK');
							$('#printArea2').printThis({
								importStyle: true
							});	
							self.loadDefaultData();
							$("#myModalPrintTicketAgain").modal("hide");
							$("#myModalPrintTicketAgain").removeClass("modal");
						
						}else if(response.data.resCode==0){
							self.createMessageBoxTrans('warning','Ticket','dataCantBeSaved');
						}else if(response.data.resCode==2){
							self.createMessageBoxTrans('danger','Ticket','dataWasNotSaved');
						}else if(response.data.resCode==3){
							self.createMessageBoxTrans('danger','Ticket','accessDenied');
						}
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
			
			}
	
		},
		
		setFontSizeLabel:function(){
			const textLength = this.ticketPrint.firstname.length+this.ticketPrint.surname.length;
			if(textLength > 22){
				this.labelFontSize=18;
			}else if(textLength > 16){
				this.labelFontSize=22;
			}else if(textLength > 12){
				this.labelFontSize=26;
			}
		},
		
		//modal new customer
		loadModalNewCustomer:function(){
			
			var self = this;
			axios({
				method: 'post',
				url: '/app/includeData/customersList/getCustAccessData.php'
			})
			.then(response => {
				if (response.data.error) {
					console.log('error', response.data.error)
				} else {
					console.log('success2', response.data);
					self.custAccess = response.data.custAccess;
					
					$('#myModalNewAccess').addClass('modal');
					$('#myModalNewAccess').modal({backdrop: "static"});
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
		},
		
		//modal edit customer
		loadModalEditCustomer:function(customerId){
			
			var bodyFormData = new FormData();
			bodyFormData.set('custAccessId',customerId);
			
			var self = this;
			axios({
				method: 'post',
				url: '/app/includeData/customersList/getCustAccessData.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
					console.log('error', response.data.error)
				} else {
					console.log('success2', response.data);
					self.custAccess = response.data.custAccess;
					
					$('#myModalEditAccess').addClass('modal');
					$('#myModalEditAccess').modal({backdrop: "static"});
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
		},
		
		checkSave:function(){
				
			
			if(!this.custAccess.email){this.custAccess.emailError = true; }else{this.custAccess.emailError = false;}
			
			//if(!this.custAccess.state){this.custAccess.stateError = true; }else{this.custAccess.stateError = false;}
			//if(!this.custAccess.access){this.custAccess.accessError = true; }else{this.custAccess.accessError = false;}
			
			if(!this.custAccess.emailError/* && !this.custAccess.stateError && !this.custAccess.accessError*/){
				return true;
			}else{
				this.createMessageBoxTrans('warning','CustomerAccess','fillInAllData');
				return false;
			}
		},	
			
		
		saveCustAccess:function(){
			
			if(this.checkSave()){
				console.log('this.custAccess', this.custAccess);
				
				var bodyFormData = new FormData();
				bodyFormData.set('myCustAccess',JSON.stringify(this.custAccess));
				//bodyFormData.set('myCustAccessId',this.custAccessId);
				
				//bodyFormData.set('optionsEventItems',JSON.stringify(this.optionsEventItems));
				
				var self = this;
				axios({
					method: 'post',
					url: '/app/includeData/customersList/controlCustAccessSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						console.log('success2', response.data);
						if(response.data.resCode==1){
							self.createMessageBoxTrans('success','CustomerAccess','dataWasSaved');
							self.custAccess = [];
							self.loadDefaultData();
							self.closeModalBoxSecure('myModalNewAccess');
							self.closeModalBoxSecure('myModalEditAccess');
						}else if(response.data.resCode==0){
							self.createMessageBoxTrans('warning','CustomerAccess','dataCantBeSaved');
						}else if(response.data.resCode==2){
							self.createMessageBoxTrans('danger','CustomerAccess','dataWasNotSaved');
						}else if(response.data.resCode==3){
							self.createMessageBoxTrans('danger','CustomerAccess','accessDenied');
						}
						
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
				
			}
			
		},
		
		
		createAccessCode:function(){
			
			if(this.custAccess.eventOnline && !this.custAccess.accessCode){
				var bodyFormData = new FormData();
				var self = this;
				axios({
					method: 'post',
					url: '/app/includeData/customersList/controlCustAccessCreateAccessCode.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						self.custAccess.accessCode = response.data.accessCode;
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
			}else{
				this.custAccess.accessCode = '';
			}
		},
		
		createQRcode:function(){
			
			if(this.custAccess.eventOffline && !this.custAccess.qrCode){
				var bodyFormData = new FormData();
				bodyFormData.set('email',this.custAccess.email);
				bodyFormData.set('title',this.custAccess.title);
				
				var self = this;
				axios({
					method: 'post',
					url: '/app/includeData/customersList/controlCustAccessCreateQRCode.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						console.log('success2', response.data);
						self.custAccess.qrCode = response.data.qrCode;
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
			}else{
				this.custAccess.qrCode = '';
			}
		}
		
	},	
	
			
	watch: {
		/*myUserReg() {
			this.loadDefaultData();
		},
		myEventReg() {
			this.loadDefaultData();
		},*/
		
	},

	computed:{
		
	},
	
});






</script>

<template>
	
	<div id="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
	
	<section v-if="customersData.resCode==1" class="">
	
		<div v-on:click="loadModalNewCustomer()" :title="$t('message.AddCustomer')" class="addAlertBtn box-flex"><font-awesome-icon :icon="['fas', 'plus']" /></div>
		
						
		<section id="obsah">
			<div class="container">
				
				<div class="row">
				
					<div class="col-12">
						<h4>Zoznam účastníkov</h4>
					</div>	
					
					<div class="col-12">
						<p>Vybavený:{{customersData.countIsChecked}}, Ostáva:{{customersData.countIsNotChecked}}</p>
					</div>	
					
					<div class="col-12">
						<p>Hľadaný výraz <input v-focus v-on:keyup="loadDefaultData()" v-model="searchTerm" type="text" class="form-control" ref="myinput"></p>
					</div>					
						
													
					<div class="col-md-12 col-12 table-responsive">
				
						<table class="table table-sm">
														
							<thead>
								<tr>
									<th width="100">ID</th>
									<th>Akcia</th>
									<th>Meno</th>
									<th>Priezvisko</th>
									<th>Spoločnosť</th>
									<th>Email</th>
								</tr>
							</thead>

							<tr v-for="item in customersData.items" v-bind:key="item">
								<td>{{item.id}}</td>
								<td class="text-left"><font-awesome-icon v-if="item.isChecked" class="text-success" :title="item.isCheckedDate" :icon="['fas', 'check']" />
								
									<button v-if="!item.isChecked" type="button" :title="$t('message.Checking')"  v-on:click="checkCode(item.qrCode)" class="btn btn-success btn-sm" ><font-awesome-icon :icon="['fas', 'check-circle']" /></button>
									
									<button v-if="item.isChecked" type="button" :title="$t('message.PrintLabel')"  v-on:click="printAgain(item.id)" class="btn btn-warning btn-sm" ><font-awesome-icon :icon="['fas', 'print']" /></button>
									
									<button v-if="!item.isChecked" type="button" :title="$t('message.EditCustomer')"  v-on:click="loadModalEditCustomer(item.id)" class="btn btn-info btn-sm" ><font-awesome-icon :icon="['fas', 'pen']" /></button>
								</td>
								<td>{{item.firstname}}</td>
								<td>{{item.surname}}</td>
								<td>{{item.company}}</td>
								<td>{{item.email}}</td>
							</tr>

						</table>

					</div>
				
				</div>
				
			</div>
		</section>
		
	</section>
	
	<section v-else-if="customersData.resCode==2">
		<div class="container">
		<div class="row">
			<div class="col-12 text-center">
				<h5 style="padding: 50px 0;">Pre zobrazenie tejto sekcie musíte byť prihlásený ako správca a mať pridelené príslušné oprávnenia</h5>
			</div>
		</div>
		</div>
	</section>
		
	<section v-else-if="customersData.resCode==3">
		<div class="container">
		<div class="row">
			<div class="col-12 text-center">
				<h5 style="padding: 50px 0;">Chýbajú vstupné údaje</h5>
			</div>
		</div>
		</div>
	</section>
			
	<section v-else-if="customersData.resCode==0">
		<div class="container">
		<div class="row">
			<div class="col-12 text-center">
				<h5 style="padding: 50px 0;">K záznamu nemáte prístup</h5>
			</div>
		</div>
		</div>
	</section>
	
	
	<div id="myModalTicket" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog" style="">
			<div class="modal-content">
				
				<div class="modal-header">
					<h6 class="modal-title"><span>{{ $t('message.CheckTicket') }}</span></h6>
					<div class="modal-button-close" v-on:click="closeModalBoxSecure('myModalTicket')" ><font-awesome-icon :icon="['fas', 'times']" /></div>
					
				</div>
		
				<div class="modal-body">
					<div v-if="resCode==1" class="row">
						<div class="col-12">
							<div class="alert alert-success" role="alert">
								Lístok je v poriadku.
							</div>
						</div>
						<div class="col-12">
							<p>{{ticket.eventName}}: 
								<span v-for="item in ticket.items" v-bind:key="item">{{item.title}}, </span>
							</p>
						</div>
						
						<div class="col-12">
							<div class="my-body" id="printArea">
								<div class="title">	
									<p class="title-text"><br><b>{{ticket.firstname}} {{ticket.surname}}</b>
									</p>
								</div>			
								
								
							</div>
						</div>
						
						<div class="col-6 box-flex">
							<button v-on:click="approveTicket()" class="btn btn-success btn-sm">{{ $t('message.Approve') }}</button>
							
						</div>
						
						<div class="col-6 box-flex">
						
							<button v-on:click="printLabel()" class="btn btn-success btn-sm">{{ $t('message.PrintLabel') }}</button>
						</div>
						
					</div>	
					
					
					<div v-if="resCode==5" class="row">
						<div class="col-12">
							<div class="alert alert-warning" role="alert">
								Lístok nie je v poriadku, ju určený pre inú konferenciu.
							</div>
						</div>
						<div class="col-12">
							<p>{{ticket.eventName}}: 
								<span v-for="item in ticket.items" v-bind:key="item">{{item.title}}, </span>
							</p>
						</div>
						
						<div class="col-12">
							<p>{{ticket.titleBefore}} {{ticket.surname}} {{ticket.firstname}} {{ticket.titleAfter}}, {{ticket.company}}, {{ticket.position}}
							</p>
						</div>
						
						<div class="col-6 box-flex">
							
						</div>
						<div class="col-6 box-flex">
							<button v-on:click="closeModalBoxSecure('myModalTicket')" class="btn btn-light btn-sm">{{ $t('message.Close') }}</button>
							
						</div>
					</div>
					
					
					
					
					
					<div v-if="resCode==4" class="row">
						<div class="col-12">
							<div class="alert alert-danger" role="alert">
								Pre lístok neexistuje event s osobnou účasťou.
							</div>
						</div>
						
						<div class="col-6 box-flex">
						</div>
						<div class="col-6 box-flex">
							<button v-on:click="closeModalBoxSecure('myModalTicket')" class="btn btn-light btn-sm">{{ $t('message.Close') }}</button>
						</div>
					</div>
					
					<div v-if="resCode==3" class="row">
						<div class="col-12">
							<div class="alert alert-danger" role="alert">
								Lístok bol stornovaný.
							</div>
						</div>
						
						<div class="col-12">
							<p><b>Dátum a čas storna lístka:</b> {{ticket.canceled_dateView}}</p>
							<p><b>Dôvod storna:</b> {{ticket.canceled_reason}}</p>
						</div>
						
						<div class="col-6 box-flex">
						</div>
						<div class="col-6 box-flex">
							<button v-on:click="closeModalBoxSecure('myModalTicket')" class="btn btn-light btn-sm">{{ $t('message.Close') }}</button>
						</div>
					</div>	
					
					<div v-if="resCode==2" class="row">
						<div class="col-12">
							<div class="alert alert-warning" role="alert">
								Pre vstupný kód neexistuje pridelená udalosť.
							</div>
						</div>
						
						<div class="col-6 box-flex">
						</div>
						<div class="col-6 box-flex">
							<button v-on:click="closeModalBoxSecure('myModalTicket')" class="btn btn-light btn-sm">{{ $t('message.Close') }}</button>
						</div>
					</div>
					
					
					<div v-if="resCode==0" class="row">
						<div class="col-12">
							<div class="alert alert-warning" role="alert">
								Vstupný kód je prázdny.
							</div>
						</div>
						
						<div class="col-6 box-flex">
						</div>
						<div class="col-6 box-flex">
							<button v-on:click="closeModalBoxSecure('myModalTicket')" class="btn btn-light btn-sm">{{ $t('message.Close') }}</button>
						</div>
					</div>
					
					<div v-if="resCode==6" class="row">
						<div class="col-12">
							<div class="alert alert-danger" role="alert">
								Lístok už bol raz vybavený.
							</div>
						</div>
						<div class="col-12">
							<p><b>Dátum a čas načítania lístka:</b> {{ticket.checked_dateView}}</p>
						</div>
						
						<div class="col-6 box-flex">
						</div>
						<div class="col-6 box-flex">
							<button v-on:click="closeModalBoxSecure('myModalTicket')" class="btn btn-light btn-sm">{{ $t('message.Close') }}</button>
						</div>
					</div>
					
				</div>
				
			</div>
		</div>
	</div>
	
	
	<div id="myModalPrintTicketAgain" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog" style="">
			<div class="modal-content">
				
				<div class="modal-header">
					<h6 class="modal-title"><span>{{ $t('message.PrintLabel') }}</span></h6>
					<div class="modal-button-close" v-on:click="closeModalBoxSecure('myModalPrintTicketAgain')" ><font-awesome-icon :icon="['fas', 'times']" /></div>
					
				</div>
		
				<div class="modal-body">
					<div v-if="resCode==1" class="row">
						<div class="col-12">
							<div class="alert alert-success" role="alert">
								Lístok je v poriadku.
							</div>
						</div>
						<div class="col-12">
							<p>{{ticket.eventName}}: 
								<span v-for="item in ticket.items" v-bind:key="item">{{item.title}}, </span>
							</p>
						</div>
						
						<div class="col-12">
							<p><b>Dátum a čas načítania lístka:</b> {{ticket.checked_dateView}}</p>
						</div>
						
						<div class="col-12">
							<div class="my-body" id="printArea">
								<div class="title">	
									<p class="title-text"><br><b>{{ticket.firstname}} {{ticket.surname}}</b>
									</p>
								</div>			
								
								
							</div>
						</div>
						
						
						
						<div class="offset-md-6 col-md-6 col-12 box-flex">
						
							<button v-on:click="printLabelAgain()" class="btn btn-success btn-sm">{{ $t('message.PrintLabel') }}</button>
						</div>
						
					</div>	
					
					
					<div v-if="resCode==5" class="row">
						<div class="col-12">
							<div class="alert alert-warning" role="alert">
								Lístok nie je v poriadku, ju určený pre inú konferenciu.
							</div>
						</div>
						<div class="col-12">
							<p>{{ticket.eventName}}: 
								<span v-for="item in ticket.items" v-bind:key="item">{{item.title}}, </span>
							</p>
						</div>
						
						<div class="col-12">
							<p>{{ticket.titleBefore}} {{ticket.surname}} {{ticket.firstname}} {{ticket.titleAfter}}, {{ticket.company}}, {{ticket.position}}
							</p>
						</div>
						
						<div class="col-6 box-flex">
							
						</div>
						<div class="col-6 box-flex">
							<button v-on:click="closeModalBoxSecure('myModalPrintTicketAgain')" class="btn btn-light btn-sm">{{ $t('message.Close') }}</button>
							
						</div>
					</div>
					
					
					
					
					
					<div v-if="resCode==4" class="row">
						<div class="col-12">
							<div class="alert alert-danger" role="alert">
								Pre lístok neexistuje event s osobnou účasťou.
							</div>
						</div>
						
						<div class="col-6 box-flex">
						</div>
						<div class="col-6 box-flex">
							<button v-on:click="closeModalBoxSecure('myModalPrintTicketAgain')" class="btn btn-light btn-sm">{{ $t('message.Close') }}</button>
						</div>
					</div>
					
					<div v-if="resCode==3" class="row">
						<div class="col-12">
							<div class="alert alert-danger" role="alert">
								Lístok bol stornovaný.
							</div>
						</div>
						
						<div class="col-12">
							<p><b>Dátum a čas storna lístka:</b> {{ticket.canceled_dateView}}</p>
							<p><b>Dôvod storna:</b> {{ticket.canceled_reason}}</p>
						</div>
						
						<div class="col-6 box-flex">
						</div>
						<div class="col-6 box-flex">
							<button v-on:click="closeModalBoxSecure('myModalPrintTicketAgain')" class="btn btn-light btn-sm">{{ $t('message.Close') }}</button>
						</div>
					</div>	
					
					<div v-if="resCode==2" class="row">
						<div class="col-12">
							<div class="alert alert-warning" role="alert">
								Pre vstupný kód neexistuje pridelená udalosť.
							</div>
						</div>
						
						<div class="col-6 box-flex">
						</div>
						<div class="col-6 box-flex">
							<button v-on:click="closeModalBoxSecure('myModalPrintTicketAgain')" class="btn btn-light btn-sm">{{ $t('message.Close') }}</button>
						</div>
					</div>
					
					
					<div v-if="resCode==0" class="row">
						<div class="col-12">
							<div class="alert alert-warning" role="alert">
								Vstupný kód je prázdny.
							</div>
						</div>
						
						<div class="col-6 box-flex">
						</div>
						<div class="col-6 box-flex">
							<button v-on:click="closeModalBoxSecure('myModalPrintTicketAgain')" class="btn btn-light btn-sm">{{ $t('message.Close') }}</button>
						</div>
					</div>
					
					<div v-if="resCode==6" class="row">
						<div class="col-12">
							<div class="alert alert-danger" role="alert">
								Lístok ešte nebol vybavený.
							</div>
						</div>
						
						
						<div class="col-6 box-flex">
						</div>
						<div class="col-6 box-flex">
							<button v-on:click="closeModalBoxSecure('myModalPrintTicketAgain')" class="btn btn-light btn-sm">{{ $t('message.Close') }}</button>
						</div>
					</div>
					
				</div>
				
			</div>
		</div>
	</div>
	
	
	<div id="myModalNewAccess" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="">
			<div class="modal-content">
				
				<div class="modal-header">
					<h6 class="modal-title"><span>{{ $t('message.NewCustomer') }}</span></h6>
					<div class="modal-button-close" v-on:click="closeModalBoxSecure('myModalNewAccess')" ><font-awesome-icon :icon="['fas', 'times']" /></div>
					
				</div>
		
				<div class="modal-body">
					<div class="row">
						<div class="col-12">
						
						
							<div class="form-row">
								<div class="form-group col-md-2 col-12">
									<label><b>{{ $t('message.TitleBefore') }}</b> </label>
									<input type="text" class="form-control form-control-sm" v-model="custAccess.titleBefore" >
								</div>
								
								<div class="form-group col-md-3 col-12">
									<label><b>{{ $t('message.Firstname') }}</b> </label>
									<input type="text" class="form-control form-control-sm" v-model="custAccess.firstname" >
								</div>
								
								<div class="form-group col-md-3 col-12">
									<label><b>{{ $t('message.Surname') }}</b> </label>
									<input type="text" class="form-control form-control-sm" v-on:change="checkSave()" v-model="custAccess.surname" >
									
								</div>
								
								<div class="form-group col-md-2 col-12">
									<label><b>{{ $t('message.TitleAfter') }}</b> </label>
									<input type="text" class="form-control form-control-sm" v-model="custAccess.titleAfter" >
								</div>
								
								
							</div>	
							
							<div class="form-row">
							
								<div class="form-group col-md-3 col-12">
									<label :class="{'text-danger':custAccess.emailError}"><b>{{ $t('message.Email') }}</b> </label>
									<input type="text" class="form-control form-control-sm" :class="{'is-invalid':custAccess.emailError}" v-on:change="checkSave()" v-model="custAccess.email" >
									<div v-show="custAccess.emailError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('message.fillIn')}}</span></div>
								</div>
							
								<div class="form-group col-md-6 col-12">
									<label><b>{{ $t('message.Company') }}</b> </label>
									<input type="text" class="form-control form-control-sm" v-model="custAccess.company" >
								</div>
								
								<div class="form-group col-md-3 col-12">
									<label><b>{{ $t('message.Position') }}</b> </label>
									<input type="text" class="form-control form-control-sm" v-model="custAccess.position" >
								</div>
							</div>
							
							<div class="form-row">
								
								
								
								<div class="form-group col-md-3 col-12">
									<label><b>ID SUBJ OSOBA</b> </label>
									<input type="text" class="form-control form-control-sm" v-model="custAccess.idSubjOsoba" >
								</div>
								
							</div>

							
							<div class="form-row">
								
								<div class="form-group col-12">
									<label><b>{{ $t('message.EventItem') }}</b> </label>
									<div  v-for="item in custAccess.optionsEventItems" :key="item" class="togglebutton">
										<label>
											<input v-model='item.isSelected' type="checkbox">
											<span class="toggle"></span>
											{{item.title}}
										</label>
									</div>
								</div>
							
							</div>
							
							<div class="form-row">
								
								<div class="form-group col-md-2 col-3">
									<label><b>{{ $t('message.Online') }}</b> </label>
																						
									<div class="togglebutton">
										<label><!--custAccess.isSent ||-->
											<input :disabled="custAccess.source == 'ESO9'" v-model='custAccess.eventOnline' type="checkbox" v-on:change="createAccessCode()">
											<span class="toggle"></span>
										</label>
									</div>
								</div>
								
								<div class="form-group col-md-10 col-9"><!--custAccess.isSent ||-->
									<label><b>{{ $t('message.AccessCode') }}</b> </label>
									<input :disabled="!custAccess.eventOnline || custAccess.source == 'ESO9'" type="text" class="form-control form-control-sm" v-model="custAccess.accessCode" >
								</div>
								
							</div>
							
							
							<div class="form-row">
								<div class="form-group col-md-2 col-3">
									<label><b>{{ $t('message.Offline') }}</b> </label>
									
									<div class="togglebutton">
										<label>
											<input :disabled="custAccess.isSent || custAccess.isTicket || custAccess.source == 'ESO9'" v-model='custAccess.eventOffline' type="checkbox" v-on:change="createQRcode()">
											<span class="toggle"></span>
										</label>
									</div>
								</div>
								
								<div class="form-group col-md-10 col-9">
									<label><b>{{ $t('message.qrCode') }}</b> </label>
									<input :disabled="!custAccess.eventOffline || custAccess.isSent || custAccess.isTicket || custAccess.source == 'ESO9'" type="text" class="form-control form-control-sm" v-model="custAccess.qrCode" >
								</div>
								
							
							</div>
							
													
							<div class="form-row">
								<div class="form-group col-12">
									<label><b>{{ $t('message.Note') }}</b> </label>
									<textarea class="form-control form-control-sm" v-model="custAccess.note" rows="2"></textarea>
								</div>
							</div>
														
							
						</div>
					</div>	
				</div>
				
				<div class="modal-footer">
							
					
						<button type="button" v-on:click="closeModalBoxSecure('myModalNewAccess')" class="btn btn-default btn-sm">{{ $t('message.btnClose') }}</button>
						
						<button type="button" :disabled="custAccess.emailError" v-on:click="saveCustAccess()" class="btn btn-warning btn-sm">{{ $t('message.btnSave') }}</button>	
						
					
				</div>
				
			</div>
		</div>
	</div>
	
	<div id="myModalEditAccess" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="">
			<div class="modal-content">
				
				<div class="modal-header">
					<h6 class="modal-title"><span>{{ $t('message.EditCustomer') }}</span></h6>
					<div class="modal-button-close" v-on:click="closeModalBoxSecure('myModalEditAccess')" ><font-awesome-icon :icon="['fas', 'times']" /></div>
					
				</div>
		
				<div class="modal-body">
					<div class="row">
						<div class="col-12">
						
						
							<div class="form-row">
								<div class="form-group col-md-2 col-12">
									<label><b>{{ $t('message.TitleBefore') }}</b> </label>
									<input type="text" class="form-control form-control-sm" v-model="custAccess.titleBefore" >
								</div>
								
								<div class="form-group col-md-3 col-12">
									<label><b>{{ $t('message.Firstname') }}</b> </label>
									<input type="text" class="form-control form-control-sm" v-model="custAccess.firstname" >
								</div>
								
								<div class="form-group col-md-3 col-12">
									<label><b>{{ $t('message.Surname') }}</b> </label>
									<input type="text" class="form-control form-control-sm" v-on:change="checkSave()" v-model="custAccess.surname" >
									
								</div>
								
								<div class="form-group col-md-2 col-12">
									<label><b>{{ $t('message.TitleAfter') }}</b> </label>
									<input type="text" class="form-control form-control-sm" v-model="custAccess.titleAfter" >
								</div>
								
								
							</div>	
							
							<div class="form-row">
							
								<div class="form-group col-md-3 col-12">
									<label :class="{'text-danger':custAccess.emailError}"><b>{{ $t('message.Email') }}</b> </label>
									<input type="text" class="form-control form-control-sm" :class="{'is-invalid':custAccess.emailError}" v-on:change="checkSave()" v-model="custAccess.email" >
									<div v-show="custAccess.emailError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('message.fillIn')}}</span></div>
								</div>
							
								<div class="form-group col-md-6 col-12">
									<label><b>{{ $t('message.Company') }}</b> </label>
									<input type="text" class="form-control form-control-sm" v-model="custAccess.company" >
								</div>
								
								<div class="form-group col-md-3 col-12">
									<label><b>{{ $t('message.Position') }}</b> </label>
									<input type="text" class="form-control form-control-sm" v-model="custAccess.position" >
								</div>
							</div>
							
							<div class="form-row">
								
								
								
								<div class="form-group col-md-3 col-12">
									<label><b>ID SUBJ OSOBA</b> </label>
									<input type="text" class="form-control form-control-sm" v-model="custAccess.idSubjOsoba" >
								</div>
								
							</div>

							
							<div class="form-row">
								
								<div class="form-group col-12">
									<label><b>{{ $t('message.EventItem') }}</b> </label>
									<div  v-for="item in custAccess.optionsEventItems" :key="item" class="togglebutton">
										<label>
											<input v-model='item.isSelected' type="checkbox">
											<span class="toggle"></span>
											{{item.title}}
										</label>
									</div>
								</div>
							
							</div>
							
							<div class="form-row">
								
								<div class="form-group col-md-2 col-3">
									<label><b>{{ $t('message.Online') }}</b> </label>
																						
									<div class="togglebutton">
										<label><!--custAccess.isSent ||-->
											<input :disabled="custAccess.source == 'ESO9'" v-model='custAccess.eventOnline' type="checkbox" v-on:change="createAccessCode()">
											<span class="toggle"></span>
										</label>
									</div>
								</div>
								
								<div class="form-group col-md-10 col-9"><!--custAccess.isSent ||-->
									<label><b>{{ $t('message.AccessCode') }}</b> </label>
									<input :disabled="!custAccess.eventOnline || custAccess.source == 'ESO9'" type="text" class="form-control form-control-sm" v-model="custAccess.accessCode" >
								</div>
								
							</div>
							
							
							<div class="form-row">
								<div class="form-group col-md-2 col-3">
									<label><b>{{ $t('message.Offline') }}</b> </label>
									
									<div class="togglebutton">
										<label>
											<input :disabled="custAccess.isSent || custAccess.isTicket || custAccess.source == 'ESO9'" v-model='custAccess.eventOffline' type="checkbox" v-on:change="createQRcode()">
											<span class="toggle"></span>
										</label>
									</div>
								</div>
								
								<div class="form-group col-md-10 col-9">
									<label><b>{{ $t('message.qrCode') }}</b> </label>
									<input :disabled="!custAccess.eventOffline || custAccess.isSent || custAccess.isTicket || custAccess.source == 'ESO9'" type="text" class="form-control form-control-sm" v-model="custAccess.qrCode" >
								</div>
								
							
							</div>
							
													
							<div class="form-row">
								<div class="form-group col-12">
									<label><b>{{ $t('message.Note') }}</b> </label>
									<textarea class="form-control form-control-sm" v-model="custAccess.note" rows="2"></textarea>
								</div>
							</div>
														
							
						</div>
					</div>	
				</div>
				
				<div class="modal-footer">
							
					
						<button type="button" v-on:click="closeModalBoxSecure('myModalEditAccess')" class="btn btn-default btn-sm">{{ $t('message.btnClose') }}</button>
						
						<button type="button" :disabled="custAccess.emailError" v-on:click="saveCustAccess()" class="btn btn-warning btn-sm">{{ $t('message.btnSave') }}</button>	
						
					
				</div>
				
			</div>
		</div>
	</div>
	
		
	<section v-if="resCode==1">
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div class="my-body" id="printArea2">
						<div class="title">	
							<span class="printed-text" :style="{fontSize:labelFontSize+'px'}"><b>{{ticketPrint.firstname}} {{ticketPrint.surname}}</b>
							</span>
						</div>
						<div class="company">	
							<span class="printed-text" :style="{fontSize:labelFontSizeCompany+'px'}"><b>{{ticketPrint.company}}</b>
							</span>
						</div>							
					</div>
				</div>
			</div>
		</div>
	</section>
	
	
</template>


