<script>

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faGlobe } from '@fortawesome/free-solid-svg-icons';
//import { faLinkedinIn, faInstagram, faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons'
//library.add(faLinkedinIn, faInstagram, faFacebookF, faTwitter, faGlobe)
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import $ from 'jquery'; 


//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '../mixins/GeneralFunctions';

import axios from 'axios';

export default {
	name: 'Exhibitors',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: ["myUserReg","myEventReg","myEventCounts","event"],
	data() {
		return {
			exhibitorsData: []
		}
	},
	
	mounted:function(){
	
		
			this.loadDefaultValues();
		
	},
	
	methods: {
		
		//default data
		loadDefaultValues:function(){
			var self = this;
			axios.get('/app/includeData/Exhibitors/getExhibitorsData.php')
			.then(function (response) {
				//console.log("loadExhibitorsData");
				console.log(response.data);
				
				//hide loader
				$('#preloader').fadeOut(200);
				
				self.exhibitorsData = response.data.exhibitorsData;
				
			})
				.catch(function (error) {
				console.log(error);
			});
		}		
	},	
	
			
	watch: {
		/*myEventReg() {
			this.loadDefaultValues();
		}*/
	},

	computed:{
		
	},
	
}

</script>

<template>
	
	<div id="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
		
		<section v-if="exhibitorsData.resCode==1" id="exhibitors">
		
			<div class="container">
				<div v-for="obj in exhibitorsData.list" v-bind:key="obj.id" class="row">
					
					<div v-if="obj.countCompanies>0 && obj.id==1" class="col-12 text-center"><h1>{{obj.title}}</h1></div>
					<div v-if="obj.countCompanies>0 && obj.id!=1" class="col-12 text-center"><h2>{{obj.title}}</h2></div>
					<div class="col-12 text-center"><p>{{obj.description}}</p></div>
					
					<div v-for="item in obj.companies" v-bind:key="item.id" class="col-12 col-sm-6 text-center" :class="{'col-md-3':obj.companies.length>=3,'col-md-6':obj.companies.length==2,'col-md-4 offset-md-4':obj.companies.length==1}" >
						<div class="row exhibitor-box">
							<div class="col-12 box-flex">
							
								<div class="exhibitor box-flex">
									
									<router-link :to="'/exhibitor/' + item.id" class="">
								
										<div class="row">
											<div class="col-12">
												<img :src="item.photoDataOrigo" :alt="item.title" class="img-fluid" >
											</div>
										</div>	
									</router-link>
									
								</div>
							</div>	
							<!--<div class="col-12 text-right">
								<p><router-link :to="'/exhibitor/' + item.id" class="more">{{ $t('message.More') }}</router-link></p>
							</div>	-->						
						</div>
						
					</div>
				
				</div>
			</div>
			
		</section>
		
		<section v-else-if="exhibitorsData.resCode==2">
			<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Pre zobrazenie tejto sekcie musíte byť prihlásený</h5>
				</div>
			</div>
			</div>
		</section>
			
		<section v-else-if="exhibitorsData.resCode==3">
			<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Chýbajú vstupné údaje</h5>
				</div>
			</div>
			</div>
		</section>
				
		<section v-else-if="exhibitorsData.resCode==0">
			<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Zoznam vystavovateľov je prázdny</h5>
				</div>
			</div>
			</div>
		</section>

</template>


