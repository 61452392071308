<script>

import { library } from '@fortawesome/fontawesome-svg-core';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn, faInstagram, faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons'
library.add(faLinkedinIn, faInstagram, faFacebookF, faTwitter, faGlobe)
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import $ from 'jquery'; 


//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '../mixins/GeneralFunctions';

import axios from 'axios';

export default {
	name: 'Speakers',
	mixins: [ MixinGeneralFunct ],
	components: {
		"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: ["myUserReg","myEventReg","myEventCounts","event"],
	data() {
		return {
			speakersData: []
		}
	},
	
	mounted:function(){
		
		
			this.loadDefaultValues();
		
		
	},
	
	methods: {
		
		//default data
		loadDefaultValues:function(){
			var self = this;
			axios.get('/app/includeData/Speakers/getSpeakersData.php')
			.then(function (response) {
				//console.log("loadSpeakersData");
				//console.log(response.data);
				
				//hide loader
				$('#preloader').fadeOut(200);
				
				self.speakersData = response.data.speakersData;
				
			})
				.catch(function (error) {
				console.log(error);
			});
		}		
	},	
	
			
	watch: {
		/*myEventReg() {
			this.loadDefaultValues();
		}*/
	},

	computed:{
		
	},
	
}

</script>

<template>
	
	<div id="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
	
	<section v-if="speakersData.resCode==1">
	
		<section id="moderators" class="">
			<div class="container">
				<div class="row">
											
					<div v-for="item in speakersData.arrModerators" v-bind:key="item.id" class="col-12 text-center" :class="{'col-md-4':speakersData.countModerators==3,'col-md-6':speakersData.countModerators==2,'col-md-4 offset-md-4':speakersData.countModerators==1}" >
						<div class="row block">
						<div class="col-12">
						
							<div class="people">	
								<router-link :to="'/speaker/' + item.id + '/speakers'" class="mySpeaker">

									<div class="row">
										<div class="col-12">
											<img :src="item.photoDataOrigo" :alt="item.firstname+' '+item.surname" class="img-fluid img-speaker" >
											
											<div class="socials">
								
									<a v-if="item.urlLinkedIn" :href="item.urlLinkedIn" target="_blank" ><font-awesome-icon :icon="['fab', 'linkedin-in']" /></a>&nbsp;&nbsp;
									<a v-if="item.urlFacebook" :href="item.urlFacebook" target="_blank" ><font-awesome-icon :icon="['fab', 'facebook-f']" /></a>&nbsp;&nbsp;
									<a v-if="item.urlWeb" :href="item.urlWeb" target="_blank" ><font-awesome-icon :icon="['fas', 'globe']" /></a>&nbsp;&nbsp;
									<a v-if="item.urlInstagram" :href="item.urlInstagram" target="_blank" ><font-awesome-icon :icon="['fab', 'instagram']" /></a>&nbsp;&nbsp;
									<a v-if="item.urlTwitter" :href="item.urlTwitter" target="_blank" ><font-awesome-icon :icon="['fab', 'twitter']" /></a>&nbsp;&nbsp;
								</div>
											
										</div>
									
										<div class="col-12">
											<div class="description">
												<span class="name">{{item.firstname}} {{item.surname}}<br></span>
												<span v-if="item.position" class="position">{{item.position}}<br></span>
												<span v-if="item.company" class="company">{{item.company}}<br></span>
											</div>
										</div>
									</div>	
								</router-link>
								
								
							</div>
						</div>		
						</div>
						
					</div>
				
				</div>
			</div>
		</section>
		
		<section id="speakers" class="">
			<div class="container">
				<div class="row">
											
					<div v-for="item in speakersData.arrSpeakers" v-bind:key="item.id" class="col-12 text-center" :class="{'col-md-3':speakersData.countSpeakers>=3,'col-md-6':speakersData.countSpeakers==2,'col-md-4 offset-md-4':speakersData.countSpeakers==1}" >
						<div class="row">
							<div class="col-12">
							
								<div class="people">
									
									<router-link :to="'/speaker/' + item.id + '/speakers'" class="mySpeaker">
								
										<div class="row">
											<div class="col-12">
												<img :src="item.photoDataOrigo" :alt="item.firstname+' '+item.surname" class="img-fluid img-speaker" >
												
												<div class="socials">
									
													<a v-if="item.urlLinkedIn" :href="item.urlLinkedIn" target="_blank" ><font-awesome-icon :icon="['fab', 'linkedin-in']" /></a>&nbsp;&nbsp;
													<a v-if="item.urlFacebook" :href="item.urlFacebook" target="_blank" ><font-awesome-icon :icon="['fab', 'facebook-f']" /></a>&nbsp;&nbsp;
													<a v-if="item.urlWeb" :href="item.urlWeb" target="_blank" ><font-awesome-icon :icon="['fas', 'globe']" /></a>&nbsp;&nbsp;
													<a v-if="item.urlInstagram" :href="item.urlInstagram" target="_blank" ><font-awesome-icon :icon="['fab', 'instagram']" /></a>&nbsp;&nbsp;
													<a v-if="item.urlTwitter" :href="item.urlTwitter" target="_blank" ><font-awesome-icon :icon="['fab', 'twitter']" /></a>&nbsp;&nbsp;
												</div>
												
											</div>
										
											<div class="col-12">
												<div class="description">
													<span class="name">{{item.firstname}} {{item.surname}}<br></span>
													<span v-if="item.position" class="position">{{item.position}}<br></span>
													<span v-if="item.company" class="company">{{item.company}}<br></span>
												</div>
											</div>
										</div>	
									</router-link>
									
								</div>
							</div>		
						</div>
						
					</div>
				
				</div>
			</div>
		</section>
		
	</section>	
		
	<section v-else-if="speakersData.resCode==2">
		<div class="container">
		<div class="row">
			<div class="col-12 text-center">
				<h5 style="padding: 50px 0;">Pre zobrazenie tejto sekcie musíte byť prihlásený</h5>
			</div>
		</div>
		</div>
	</section>
		
	<section v-else-if="speakersData.resCode==3">
		<div class="container">
		<div class="row">
			<div class="col-12 text-center">
				<h5 style="padding: 50px 0;">Chýbajú vstupné údaje</h5>
			</div>
		</div>
		</div>
	</section>
			
	<section v-else-if="speakersData.resCode==0">
		<div class="container">
		<div class="row">
			<div class="col-12 text-center">
				<h5 style="padding: 50px 0;">Zoznam rečníkov je prázdny</h5>
			</div>
		</div>
		</div>
	</section>
		

</template>


