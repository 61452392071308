<script>

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { defineComponent } from 'vue';

import $ from 'jquery'; 

//general functions
import MixinGeneralFunct from '../mixins/GeneralFunctions';

import axios from 'axios';

export default defineComponent ({
	name: 'LoginPage',
	mixins: [ MixinGeneralFunct ],
	components: {
		"font-awesome-icon":FontAwesomeIcon,
	},
	
	directives: {
		focus: {
			// directive definition
			mounted(el) {
				el.focus()
			}
		}
	},

	props: ["myUserReg","myEventReg","myEventCounts","myEventTitle","event"],
		
	
	data() {
		return {
			prefix:'',
						
			accData: [],
			secData: [],
			auth: [],
			menuItems: [],
			cookiesTime: 259200,
			
			eventIsRegistered:false,
			eventRegCode:'',
			selectedAccessCode:'',
			
			loadInterval:'',
			
			isLogged:true,
			
			alert:[],
			alertEdit:[],
			
			myEvent:[],
			
			
			optionsTypes:[],
			
			readerData: [],
			
			helpboxData: [],
			
			//alertsCount:0,
			ticket:[],
			ticketPrint:[],
			resCode:'',
			labelFontSize:26,
			labelFontSizeCompany:16,
			
			timeToRefresh:10000,
			
			firstLoading:true,
			tmpQuestions:[],
	
			countPages:0,
			selectedPage:null,
			selectedRecords:null,
			limit_from:0,
			limit_to:0,
			
			isSelectedQuestion:false,
			curSelectedQuestionID:'',
			
			selectedCol:'created',
			selectedSort:'desc',
		
			filterExtended:true,
			showFilter:false,
			
			myCode:'',
			decodedString:'',
			
			decodedString1:'',
			stateReader:true,

			user:[],
			
			eventCounts:[],
			
			options: {
				type: Object,
				default() {
					return {};
				}
			}
			
		}
	},
	
	
	
	mounted:function(){
		//this.loadDefaultData();
		console.log(this.event);
		if(this.event.isReg){
			location.replace("/home");
			//this.$router.push({ path: '/home' })
		}

		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);

		this.token =  urlParams.get('auth')
		
		if(this.token && !this.event.isReg){
			this.regEvent();
		}

	//	console.log(this.token);

		$('#preloader').fadeOut(200);
	},
	
	methods: {
				
		//default data
		loadDefaultData:function(){
			var self = this;
			axios.get('/app/includeData/Common/getDefaultDataLogin.php')
			.then(function (response) {
				//console.log("loadDefData");
				//console.log(response.data);
				
				self.user = response.data.user;
				self.event = response.data.event;
				self.eventCounts = response.data.eventItemCounts;
				
				
				if(self.event.isReg){
					self.resetLoadingInterval();
					self.resetAlertInterval();
				}
				
			})
				.catch(function (error) {
				console.log(error);
			});
		},
		

		//registracia event
		regEvent:function(){
			if(this.selectedAccessCode || this.token){
				
				if(this.token){
					this.token = this.token.replace(" ", "+");
				}
			
				var self = this;
				axios.get('/app/includeData/Common/controlEventReg.php',{
					params:{
						//tu hashuj accessCose !!!!!!!!
						selectedAccessCode:this.selectedAccessCode,
						token:this.token
					}
				})
				.then(function (response) {
					
					//console.log(response.data);
					if(response.data.resCode==1){
						//self.setCookie("eventRegCode", self.selectedAccessCode, self.cookiesTime);
						self.setCookie("eventReg", 1, self.cookiesTime);
						self.event.isReg=true;
					//	self.loadDefaultValues();
						
						//self.resetLoadingInterval();
						//self.resetAlertInterval();
						window.location.href = '/home';
					//	self.$router.push({ path: '/home' })
			//			self.$router.push({ path: self.$route.path })
						//if(self.$route.path == '/'){
			//				location.reload(); 
						//}else{
							//location.reload(); 
							//self.$router.push({ path: '/' })
						//}

					}else if(response.data.resCode==2){
						self.createMessageBoxTrans('warning','Event','udalostPreZadanyKlucNeexistuje');
						
					}else if(response.data.resCode==3){
						
						self.createMessageBoxTrans('danger','Event','pristupBolStornovany');

					}else if(response.data.resCode==0){
						self.createMessageBoxTrans('warning','Event','pristupPreUzivatelaNeexistuje');
					}
										
				})
					.catch(function (error) {
					console.log(error);
				});
			
			}
		},

				
		//messagebox v1.1
		createMessageBoxNoTrans: function(kind,object,message) {
			var content = "";
			content += "<div class='alert alert-"+kind+"' role='alert'>";
			content += message;
			content += "</div>";
			
			var rand = this.getRandomInt(600000);
			$('#msg').append("<div class='msg"+rand+"'></div>");
			$('.msg'+rand).html("").append(content).fadeIn(100).delay(6000).fadeOut(300);
		},
		
		//messagebox v1.1
		createMessageBoxTrans: function(kind,object,message) {
			var content = "";
			content += "<div class='alert alert-"+kind+"' role='alert'>";
			content += this.$t('message.'+message);
			content += "</div>";
			
			var rand = this.getRandomInt(600000);
			$('#msg').append("<div class='msg"+rand+"'></div>");
			$('.msg'+rand).html("").append(content).fadeIn(100).delay(6000).fadeOut(300);
		},
		
		// zatvaranie modalbox
		closeModalBoxSecure:function(myform){
			$("#"+myform).modal("hide");
			$("#"+myform).removeClass("modal");
			this.startReader();
			
		},
		
	},	
	
			
	watch: {
		
	},

	computed:{
		
	},
	
});






</script>

<template>

	<div>

		<div id="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>

		<section v-if="!event.isReg" id="intro" class="box-flex">
			<div class="container-fluid">

				<div class="row">
					<div class="col-12 col-md-8 offset-md-2 box-flex text-center">
						<h4>{{ $t('message.WelcomeInExpoPP') }}<br><span>{{ $t('message.byCompanyPP') }}</span><br><br></h4>
					</div>
				</div>

			</div>
		</section>

		<section v-if="!event.isReg" id="registracia" class="box-flex">
			<div class="container-fluid">
				
				<div class="row">
					
					<div class="col-12 col-md-6 box-flex">
						<div class="row box-0">
							<div class="input-group col-12">
								<input v-focus class="form-control" v-on:keyup.enter="regEvent" v-model="selectedAccessCode" :placeholder="$t('message.tuZadajtePristupovyKod')">
								<span title="Odoslať" v-on:click="regEvent()" class="my-sent-btn"><font-awesome-icon :icon="['fas', 'arrow-alt-circle-right']" /></span>
							</div>
						</div>
					</div>
					
					<div class="col-12 col-md-6 box-flex">
						
						<img class="img-fluid" src="/images/uvod_img.svg" alt="Poradca podnikatela" title="Poradca podnikatela">
					
					</div>
					
					
				</div>

			</div>
		</section>

	</div>
	
</template>


