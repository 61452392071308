<script>

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
//library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import $ from 'jquery'; 

import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import sk from 'video.js/dist/lang/sk.json';

//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '../mixins/GeneralFunctions';

import axios from 'axios';

export default {
	name: 'LiveStream',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: ["myUserReg","myEventReg","myEventCounts","event"],
	data() {
		return {
			prefix:'',
						
			accData: [],
			secData: [],
			auth: [],
			menuItems: [],
			cookiesTime: 259200,
			
			eventIsRegistered:false,
			eventRegCode:'',
			selectedAccessCode:'',
			
			loadInterval:'',
			
			isLogged:true,
			
			question:[],
			questionEdit:[],
			myEvent:[],
			myUser:[],
			countCharQuestion:250,
			countCharQuestionEdit:250,
			countCharQuestionLimit:250,
			
			streamData: [],
			
			helpboxData: [],
			
			questionsCount:0,
			questions:[],
			sysQuestions:[],
			
			timeToRefresh:10000,
			
			firstLoading:true,
			tmpQuestions:[],
	
			countPages:0,
			selectedPage:null,
			selectedRecords:null,
			limit_from:0,
			limit_to:0,
			
			isSelectedQuestion:false,
			curSelectedQuestionID:'',
			
			selectedCol:'created',
			selectedSort:'desc',
		
			filterExtended:true,
			showFilter:false,
			
			options: {
				type: Object,
				default() {
					return {};
				}
			}
			
		}
	},
	
	mounted:function(){
		
				
		var DcSortCol = this.getCookie("cSortCol");   
		if (DcSortCol != "") {
			this.selectedCol = DcSortCol;
		}
		
		var DcSortDir = this.getCookie("cSortDir");   
		if (DcSortDir != "") {
			this.selectedSort = DcSortDir;
		}
		
		var eventReg = this.getCookie("eventReg");   
		if (eventReg == 1) {
			this.eventIsRegistered = true;
		}
		
		//this.loadDefaultValues();
		//if(this.eventIsRegistered){
			//this.loadQuestions();
			//this.loadInterval = setInterval(this.loadQuestions, this.timeToRefresh);
			this.loadRecords();
			
			//self.loadQuestions();
			//			self.loadLiveStreams();
			//			self.resetLoadingInterval();
			
		//}	
		
	},
	
	methods: {
		
		//na fontende aj preview aj tabula, zober povodne idcka a idcka po aktualizacii a tie naviac zobraz na par sekund inou farbou, alebo ako nove.
		
		//default data
		
		
		runRecord:function(item){
			
			var mySrc = item.url;
			
			$('#my-record_'+item.id).show();
			$('#offline-warning-record_'+item.id).hide();
			
			//init live stream
			var myVideoObjectWidth = document.getElementById("my-record-content_"+item.id).offsetWidth;
							
			var options = {width:myVideoObjectWidth-30,height:'auto',sources:[{src: mySrc,type: 'video/mp4'}],language:this.$i18n.locale,languages:{sk},responsive:true,controls:true,preload:'auto',poster:'../images/poster-zaznam.jpg'};
			
			//odstranim objekt s videom
			var el = document.getElementById('my-record2020_'+item.id);
			videojs(el).dispose();
			
			//pridam objekt video
			var x = '<video	id="my-record2020_' + item.id +'"	class="video-js vjs-default-skin vjs-big-play-centered"></video>';
			$( '#my-record_'+item.id ).append(x);
			
			videojs('my-record2020_'+item.id, options, function onPlayerReady(){
				
			});
			
		},
		
				
		
		//natiahnutie streamu
		loadRecords:function(){
						
			var self = this;
			axios.get('/app/includeData/Records/getRecordsData.php',{
				params:{
					//code:c,
					//selectedIds: this.selectedIds
				}
			})
			.then(function (response) {
				//console.log("loadRecords");
				//console.log(response.data);
				
				//hide loader
				$('#preloader').fadeOut(200);
				
				self.streamData = response.data.streamData;
				
				//priradi zaznam k id objektu
				if(self.streamData.countRecords>0){
					for(var i=0;i<self.streamData.arrRecords.length;i++){
						setTimeout(self.runRecord, 300,self.streamData.arrRecords[i]);
					}
				}
				
				/*if(self.streamData.countRecords>0){
					for(var i=0;i<self.recPlayer.length;i++){
						setTimeout(self.runRecord, 300,self.streamData.arrRecords[i]);
					}
				}
				
				for(var i=0;i<self.recPlayer.length;i++){
					setTimeout(self.runRecord, 300,self.streamData.arrRecords[i]);
				}
				.dispose()*/
				
				
			})
				.catch(function (error) {
				console.log(error);
			});
		},
				
		//messagebox v1.1
		createMessageBoxNoTrans: function(kind,object,message) {
			var content = "";
			content += "<div class='alert alert-"+kind+"' role='alert'>";
			content += message;
			content += "</div>";
			
			var rand = this.getRandomInt(600000);
			$('#msg').append("<div class='msg"+rand+"'></div>");
			$('.msg'+rand).html("").append(content).fadeIn(100).delay(6000).fadeOut(300);
		},
		
		//messagebox v1.1
		createMessageBoxTrans: function(kind,object,message) {
			var content = "";
			content += "<div class='alert alert-"+kind+"' role='alert'>";
			content += this.$t('message.'+message);
			content += "</div>";
			
			var rand = this.getRandomInt(600000);
			$('#msg').append("<div class='msg"+rand+"'></div>");
			$('.msg'+rand).html("").append(content).fadeIn(100).delay(6000).fadeOut(300);
		}
				
	},	
		
	watch: {
		/*myEventReg() {
			this.loadRecords();
		}*/
	},

	computed:{
		/*sortedBasicData:function() {
			//if(this.questions){
			return this.questions.slice().sort((a,b) => {
			let modifier = 1;
			if(this.selectedSort === 'desc') modifier = -1;
			if(a[this.selectedCol] < b[this.selectedCol]) return -1 * modifier;
			if(a[this.selectedCol] > b[this.selectedCol]) return 1 * modifier;
			return 0;
			});
			//}
		},*/
	},
	
}






</script>

<template>
	
	<div id="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
	
	<div id="msg"></div>
	<div id="msgForm"></div>

					
	<section v-if="streamData.resCode==1" id="videostream">

		<div class="container">
			<div class="row">
				<div class="col-md-12 col-xs-12 text-left">
					<h3>{{ $t('message.ConferenceRecord') }}</h3>
				</div>
			</div>
		</div>

		<div class="container">
		
			<div class="row">
				<div v-for="item in streamData.arrRecords" v-bind:key="item.id" class="col-12" :class="{'col-md-4':streamData.countRecords==3,'col-md-6':streamData.countRecords==2,'col-md-6 offset-md-3':streamData.countRecords==1}" >
		
					<div class="row">
						<div class="col-md-12 col-xs-12 text-left">
							<p><br>{{ item.title }}</p>
						</div>
					</div>
					
					<div :id="'my-record-content_' + item.id"  class="row">
						<div :id="'my-record_' + item.id" class="col-md-12 col-sm-12 col-xs-12" style="display:none;">
							<video
								:id="'my-record2020_' + item.id"
								class="video-js vjs-default-skin vjs-big-play-centered"
							>
							</video>
						</div>
					</div>
					
				</div>
			</div>
		
		</div>
	</section>
	
	
	<section v-else-if="streamData.resCode==2">
		<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Pre sledovanie záznamu musíte byť prihlásený</h5>
				</div>
			</div>
		</div>
	</section>
		
	<section v-else-if="streamData.resCode==3">
		<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Chýbajú vstupné údaje</h5>
				</div>
			</div>
		</div>
	</section>
			
	<section v-else-if="streamData.resCode==0">
		<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Záznam nie je dostupný</h5>
				</div>
			</div>
		</div>
	</section>
	
			


</template>