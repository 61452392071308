<script>

import { library } from '@fortawesome/fontawesome-svg-core';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn, faInstagram, faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons'
library.add(faLinkedinIn, faInstagram, faFacebookF, faTwitter, faGlobe)
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import $ from 'jquery'; 


//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '../mixins/GeneralFunctions';

import axios from 'axios';

export default {
	name: 'Speakers',
	mixins: [ MixinGeneralFunct ],
	components: {
		"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: ["myUserReg","myEventReg","myEventCounts","event"],
	data() {
		return {
			speakerId:null,
			speakerData: [],
			bckLink:null,
		}
	},
	
	mounted:function(){
		
		this.speakerId = this.$route.params.id;
		this.bckLink = this.$route.params.bcklnk;
		//console.log(this.bckLink);
		//this.loadDefaultValues();
		
			this.loadDefaultValues();
		
	},
	
	methods: {
		
		//default data
		loadDefaultValues:function(){
			//console.log(this.speakerId);
			var self = this;
			axios.get('/app/includeData/Speakers/getSpeakerData.php',{
				params:{
					id:this.speakerId,
				}
			})
			.then(function (response){
				//console.log("loadSpeakerData");
				//console.log(response.data);
				
				//hide loader
				$('#preloader').fadeOut(200);
				
				self.speakerData = response.data.speakerData;
				
			})
				.catch(function (error) {
				console.log(error);
			});
		}		
	},	
	
			
	watch: {
		/*myEventReg() {
			this.loadDefaultValues();
		}*/
	},

	computed:{
		
	},
	
}

</script>

<template>
	
	<div id="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
	
		<section id="head-buttons" class="">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12" >
						<router-link :to="'/' + bckLink "><button class="btn btn-secondary btn-sm">{{ $t('message.Back') }}</button></router-link>
					</div>
				</div>	
			</div>
		</section>	
		
		<section v-if="speakerData.resCode==1" id="speaker" class="">
			<div class="container">
				<div class="row">
					
					<div class="col-12 text-center col-md-8 offset-md-2" >
						<h3><span class="name">{{speakerData.titleBefore}} {{speakerData.firstname}} {{speakerData.surname}} {{speakerData.titleAfter}}<br></span></h3>
					</div>
					
					<div class="col-12 text-center col-md-4 offset-md-4" >
						
						<div class="row">
							<div class="col-12">
							
								<div class="people">	
									

									<div class="row mySpeaker">
										<div class="col-12">
											<img :src="speakerData.photoDataOrigo" :alt="speakerData.firstname+' '+speakerData.surname" class="img-fluid img-speaker" >
										</div>
									
										<div class="col-12">
											<div class="description">
												<span class="position">{{speakerData.position}}<br></span>
												<span class="company">{{speakerData.company}}<br></span>
											</div>
										</div>
									</div>	
									
									
								</div>
							</div>		
						</div>
					</div>

					<div class="col-12 text-center col-md-8 offset-md-2" >
						<div class="row">
							<div class="col-12">
								<p><span class="bio">{{speakerData.bio}}</span></p>
							</div>		
						</div>
						
						<div class="row">
							<div class="col-12 socials">
								<div class="row box-flex">
									<div class="col-2">
										<a v-if="speakerData.urlLinkedIn" :href="speakerData.urlLinkedIn" target="_blank" ><font-awesome-icon :icon="['fab', 'linkedin-in']" /></a>
									</div>
									
									<div class="col-2">
										<a v-if="speakerData.urlFacebook" :href="speakerData.urlFacebook" target="_blank" ><font-awesome-icon :icon="['fab', 'facebook-f']" /></a>
									</div>
									
									<div class="col-2">
										<a v-if="speakerData.urlWeb" :href="speakerData.urlWeb" target="_blank" ><font-awesome-icon :icon="['fas', 'globe']" /></a>
									</div>
									
									<div class="col-2">
										<a v-if="speakerData.urlInstagram" :href="speakerData.urlInstagram" target="_blank" ><font-awesome-icon :icon="['fab', 'instagram']" /></a>
									</div>
									
									<div class="col-2">
										<a v-if="speakerData.urlTwitter" :href="speakerData.urlTwitter" target="_blank" ><font-awesome-icon :icon="['fab', 'twitter']" /></a>
									</div>
								</div>
							</div>		
						</div>
						
					</div>
				
				</div>
			</div>
		</section>
		
		<section v-else-if="speakerData.resCode==2">
			<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Pre zobrazenie tejto sekcie musíte byť prihlásený</h5>
				</div>
			</div>
			</div>
		</section>
			
		<section v-else-if="speakerData.resCode==3">
			<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">Chýbajú vstupné údaje</h5>
				</div>
			</div>
			</div>
		</section>
				
		<section v-else-if="speakerData.resCode==0">
			<div class="container">
			<div class="row">
				<div class="col-12 text-center">
					<h5 style="padding: 50px 0;">K záznamu nemáte prístup</h5>
				</div>
			</div>
			</div>
		</section>

</template>


