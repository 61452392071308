import { createApp } from 'vue'
import App from './App.vue'


import { QrReader } from 'vue3-qr-reader';//funguje ale je to kopia z povodneho, cez setup()
import { createI18n } from 'vue-i18n'
import { createRouter, createWebHistory } from 'vue-router'

//views
import myUvod from './components/Uvod.vue'
import myLogin from './components/Login.vue'
import myStream from './components/Stream.vue'
import myDocuments from './components/Documents.vue'
import myStreams from './components/Streams.vue'
import mySpeakers from './components/Speakers.vue'
import mySpeaker from './components/Speaker.vue'
import myProgram from './components/Program.vue'
import myOBS from './components/Obs.vue'
import myRecords from './components/Records.vue'
import myExhibitors from './components/Exhibitors.vue'
import myExhibitor from './components/Exhibitor.vue'
import myAlerts from './components/Alerts.vue'
import myQRreader from './components/QRreader.vue'
import myLineReader from './components/lineReader.vue'
import myManualChecking from './components/manualChecking.vue'
import pathNotFound from './components/PageWasNotFound.vue'

//router
const router = createRouter({
  history: createWebHistory('/'),
  routes: [
	{ path: '/', component: myUvod },
	{ path: '/login', component: myLogin },
	{ path: '/home', component: myUvod },
	{ path: '/stream/:id', component: myStream },
	{ path: '/streams', component: myStreams },
	{ path: '/documents', component: myDocuments },
	{ path: '/speakers', component: mySpeakers },
	{ path: '/speaker/:id/:bcklnk', component: mySpeaker },
	{ path: '/exhibitors', component: myExhibitors },
	{ path: '/exhibitor/:id', component: myExhibitor },
	{ path: '/program', component: myProgram },
	{ path: '/obs', component: myOBS },
	{ path: '/records', component: myRecords },
	{ path: '/alerts', component: myAlerts },
	{ path: '/check-tickets-qr', component: myQRreader },
	{ path: '/check-tickets-line', component: myLineReader },
	{ path: '/check-tickets-manual', component: myManualChecking },
	{ path: '/:pathMatch(.*)*', component: pathNotFound }
  ],
})



//import jazykov
import { languages } from './index.js'
import { defaultLocale } from './index.js'
const messages = Object.assign(languages)
const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: 'sk',
  messages,
})




createApp(App).use(router).use(i18n).use(QrReader).mount('#app')

//.use(QrReader)

